/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Grid, TextField, Switch, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import styles from '../../../styles/Layout.module.css';
import api from '../../../service/axios/api';
import MyButton from '../../../components/button/MyButton';

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));

// eslint-disable-next-line prettier/prettier
export default function UploadPlan({
  refQtdCards,
  setRefQtdCards,
  nf,
  setNf,
  boleto,
  setBoleto,
  paymentDate,
  setPaymentDate,
  isNew,
  orderName,
  setOrderName,
  nfComments,
  setNfComments,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false)
  // const handleChangeBoleto = e => {
  //   if (e) {
  //     setBoleto(e);
  //     setNf(e);
  //   } else {
  //     setBoleto(e);
  //   }
  // };

  const handleChangeNf = e => {
    setNf(e.target.value)
    if (e.target.value === 'NFND') {
      setOpenModal(true)
    }
  };

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>{t('newOrder.qtdCards.label')}</h6>
        {isNew && (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Tipo de documento</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={handleChangeNf}
                value={nf}
              >
                <FormControlLabel value="NF" control={<Radio />} label="NF" />
                <FormControlLabel value="NFND" control={<Radio />} label="NF+ND" />
                {!api.currentUser.user.role.is_client && <FormControlLabel value="ND" control={<Radio />} label="ND" />}
              </RadioGroup>
            </FormControl>
            {/* <Grid item>
              {t('newOrder.qtdCards.nfLabel')}
              <Switch
                checked={nf}
                onChange={e => handleChangeNf(e.target.checked)}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </Grid> */}
            {/* <Grid item>
              {t('newOrder.qtdCards.ticket')}
              <Switch
                checked={boleto}
                onChange={e => handleChangeBoleto(e.target.checked)}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </Grid> */}
            {/* <Grid item>
              <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  // style={{ backgroundColor: '#fff', borderRadius: '30px' }}
                  label={t('newOrder.qtdCards.expirationDate')}
                  size="small"
                  format="dd/MM/yyyy"
                  value={paymentDate}
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: '25px',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '155px',
                    },
                  }}
                  onChange={date => setPaymentDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}
          </div>
        )}
      </div>
      {isNew && (
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item xs={5} style={{ marginTop: '15px', marginRight: '10px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.qtdCards.orderName')}
              value={orderName}
              onChange={e => setOrderName(e.target.value)}
              // inputRef={refQtdCards}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: '15px', marginLeft: '10px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.qtdCards.comments')}
              value={nfComments}
              onChange={e => setNfComments(e.target.value)}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'start',
          alignItems: 'center',
          paddingBottom: 10,
          marginTop: isNew ? 10 : 50,
          marginBottom: 50,
        }}
      >
        <Grid item>
          <p className={styles.labelTitleResum}>
            {t('newOrder.qtdCards.qtdCards')}
          </p>
          <TextField
            type="number"
            className={classes.text}
            variant="outlined"
            size="small"
            value={refQtdCards}
            onChange={e => setRefQtdCards(e.target.value)}
            // inputRef={refQtdCards}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        {/* <Grid item>
          <p className={styles.labelTitleResum}>Valor</p>
          <TextField
            className={classes.text}
            variant="outlined"
            size="small"
            value={refVlCards}
            onChange={e => setRefVlCards(e.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid> */}
      </Grid>
      <Dialog open={openModal}>
        <DialogTitle>Importante</DialogTitle>
        <DialogContent>
          <DialogContentText>Você selecionou a opção de emissão de NF + ND, com isso receberá uma Nota Fiscal com os valores de Serviços e uma Nota de Débito com os valores de repasse. Confirma?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
              backgroundColor: '#657588',
            }}
            onClick={() => {
              setNf('NF')
              setOpenModal(false)
            }}
            label="NÃO"
          />
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
            }}
            onClick={() => setOpenModal(false)}
            label="SIM"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
