/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Avatar,
  LinearProgress,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import Select from 'react-select';
import 'moment/locale/pt';

import {
  FaWallet,
  FaChartBar,
  FaDollarSign,
  FaUserFriends,
  FaRegCreditCard,
  FaPiggyBank,
  FaShieldAlt,
} from 'react-icons/fa';

import {
  CartesianGrid,
  Legend,
  Area,
  AreaChart,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';
import Loading from '../../components/loading/Loading';
import TopFiveTale from './TopFiveTable';
import routing from '../../service/routes/routing';
import styles from '../../styles/Layout.module.css';
import stylesDash from '../../styles/Dashboard.module.css';
import api from '../../service/axios/api';
import FlashContext from '../../components/contexts/FlashContext';
import Contratos4Sign from './Contratos4Sign';

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
    margin: 5,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const monthOpt = [
  {
    label: 'Janeiro',
    value: 0,
  },
  {
    label: 'Fevereiro',
    value: 1,
  },
  {
    label: 'Março',
    value: 2,
  },
  {
    label: 'Abril',
    value: 3,
  },
  {
    label: 'Maio',
    value: 4,
  },
  {
    label: 'Junho',
    value: 5,
  },
  {
    label: 'Julho',
    value: 6,
  },
  {
    label: 'Agosto',
    value: 7,
  },
  {
    label: 'Setembro',
    value: 8,
  },
  {
    label: 'Outubro',
    value: 9,
  },
  {
    label: 'Novembro',
    value: 10,
  },
  {
    label: 'Dezembro',
    value: 11,
  },
];

const yearOpt = [
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
];

const DataFormater = number => {
  if (number > 1000000000) {
    return `${(number / 1000000000).toString()}B`;
  }
  if (number > 1000000) {
    return `${(number / 1000000).toString()}M`;
  }
  if (number > 1000) {
    return `${(number / 1000).toString()}K`;
  }
  return number.toString();
};

function renderActiveShape(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {moment(payload.name).format('dddd')} */}
        {moment(payload.name).format('DD/MM')}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} pedidos`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}

function renderActiveShapeD(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {moment(payload.name).format('dddd')} */}
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(value)}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}

function renderActiveShapeMonth(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {moment(payload.name).format('dddd')} */}
        {moment(payload.name).format('MMMM')}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} pedidos`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}

function CustomizedLabel(props) {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)}
    </text>
  );
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function Painel() {
  const { notifyError } = useContext(FlashContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [topFive, setTopFive] = useState([]);
  const [dataDay, setDataDay] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [amauntMonth, setAmauntMonth] = useState([]);
  const [activeIndex, setActiveIndexId] = useState(0);
  const [activeIndexMonth, setActiveIndexIdMonth] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartmonth, setPieChartMonth] = useState([]);
  const [amauntByProduct, setAmauntByProduct] = useState([]);
  const [pieChartDivider, setPieChartDivider] = useState([]);

  const [amauntDay, setAmauntDay] = useState([]);
  const [amauntByProductDay, setAmauntByProductDay] = useState([]);

  // totais
  const [totalCards, setTotalCards] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmauntCards, setTotalAmauntCards] = useState(0);
  const [ticket, setTicket] = useState(0);
  const [totalAmauntTransfer, setTotalAmaunTransfer] = useState(0);
  const [totalAmaunt, setTotalAmaunt] = useState(0);
  const [totalCardRate, setTotalCardRate] = useState(0);
  const [totalTransferRate, setTotalTransferRate] = useState(0);

  // filtro mes e ano
  const [yearSelected, setYear] = useState(null);
  const [monthSelected, setMonth] = useState(null);

  const [monthVisible, setMonthVisible] = useState(true);
  const [dayVisible, setDayVisible] = useState(true);

  const [fourSign, setFourSign] = useState([]);

  const searchDefault = () => {
    setLoading(true);
    const cancel = new AbortController();
    api.contract
      .getContract4SignAll(cancel, 3)
      .then(res => {
        setFourSign(res);
      })
      .catch(err => {
        console.log(err);
      });

    api.order
      .summary(null, null, 'day', cancel)
      .then(res => {
        const list = [];
        const pie = [];

        res.retorno.forEach((r, i) => {
          list.push({
            name: moment(r.date).format('DD/MM'),
            pedidos: r.orders,
          });
          pie.push({
            name: r.date,
            value: r.orders,
          });
        });
        pie.sort(function (a, b) {
          return b.value - a.value;
        });

        setDataDay(list);
        setPieChartData(pie);
      })
      .catch(err => console.log(err));
    api.order
      .summary(null, null, 'month', cancel)
      .then(res => {
        const list = [];
        const pie = [];
        let tot = 0.0;
        res.retorno.forEach(r => {
          list.push({
            name: moment(r.date).format('MMMM'),
            pedidos: r.orders,
          });
          pie.push({
            name: r.date,
            value: r.orders,
          });
          tot += parseFloat(r.orders);
        });
        pie.sort(function (a, b) {
          return b.value - a.value;
        });
        setDataMonth(list);
        setPieChartMonth(pie);
        setTotalOrders(tot);
      })
      .catch(err => console.log(err));

    api.order
      .summaryItems(null, null, 'month', cancel)
      .then(res => {
        const list = [];
        const product = [];
        let totCard = 0.0;
        let totTransfer = 0.0;
        let transferRate = 0.0;
        let cardRate = 0.0;
        res.retorno.list.forEach(r => {
          list.push({
            name: moment(r.date).format('MMMM'),
            amount: r.amount,
          });
          product.push({
            name: moment(r.date).format('MMMM'),
            card: r.card,
            transfer: r.transfer,
          });
          totCard += parseFloat(r.card);
          totTransfer += parseFloat(r.transfer);
          transferRate += parseFloat(r.TransferAdmFee);
          cardRate += parseFloat(r.CardAdmFee);
        });
        setTicket(res.retorno.ticket);
        setAmauntByProduct(product);
        setAmauntMonth(list);
        setTotalAmauntCards(totCard);
        setTotalAmaunTransfer(totTransfer);
        setTotalAmaunt(totCard + totTransfer + cardRate + transferRate);
        setPieChartDivider([
          { name: 'Element', value: cardRate + transferRate },
          {
            name: 'Volume Global',
            value: totCard + totTransfer,
          },
        ]);
        setTotalTransferRate(transferRate);
        setTotalCardRate(cardRate);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));

    api.order
      .summaryItems(null, null, 'day', cancel)
      .then(res => {
        const list = [];
        const product = [];
        res.retorno.list.forEach(r => {
          list.push({
            name: moment(r.date).format('DD/YY'),
            amount: r.amount,
          });
          product.push({
            name: moment(r.date).format('DD/YY'),
            card: r.card,
            transfer: r.transfer,
          });
        });
        setAmauntByProductDay(product);
        setAmauntDay(list);
      })
      .catch(err => console.log(err));

    api.cards
      .summary(null, null, cancel)
      .then(res => {
        setTotalCards(res.retorno);
      })
      .catch(err => console.log(err));

    api.order
      .summaryTopFive(null, null, 'month', cancel)
      .then(res => {
        const list = [];
        res.retorno.forEach(r => {
          list.push({ company: r.company, order: r.orders, amount: r.amount });
        });
        setTopFive(list);
      })
      .catch(err => console.log(err));
  };

  const searchSpecific = () => {
    setLoading(true);
    const cancel = new AbortController();

    if (monthSelected) {
      api.order
        .summary(monthSelected, yearSelected, 'day', cancel)
        .then(res => {
          const list = [];
          const pie = [];
          let tot = 0.0;
          res.retorno.forEach((r, i) => {
            list.push({
              name: moment(r.date).format('DD/MM'),
              pedidos: r.orders,
            });
            pie.push({
              name: r.date,
              value: r.orders,
            });
            tot += parseFloat(r.orders);
          });
          pie.sort(function (a, b) {
            return b.value - a.value;
          });

          setDataDay(list);
          setPieChartData(pie);
          setTotalOrders(tot);
        })
        .catch(err => console.log(err));
      api.order
        .summaryItems(monthSelected, yearSelected, 'day', cancel)
        .then(res => {
          const list = [];
          const product = [];
          // res.retorno?.forEach(r => {
          //   list.push({
          //     name: moment(r.date).format('DD/YY'),
          //     amount: r.amount,
          //   });
          //   product.push({
          //     name: moment(r.date).format('DD/YY'),
          //     card: r.card,
          //     transfer: r.transfer,
          //   });
          // });
          // setAmauntByProductDay(product);
          // setAmauntDay(list);

          // ACIMA EXISTE
          // const list = [];
          // const product = [];
          let totCard = 0.0;
          let totTransfer = 0.0;
          let transferRate = 0.0;
          let cardRate = 0.0;
          res.retorno.list.forEach(r => {
            list.push({
              name: moment(r.date).format('DD/YY'),
              amount: r.amount,
            });
            product.push({
              name: moment(r.date).format('DD/YY'),
              card: r.card,
              transfer: r.transfer,
            });
            totCard += parseFloat(r.card);
            totTransfer += parseFloat(r.transfer);
            transferRate += parseFloat(r.TransferAdmFee);
            cardRate += parseFloat(r.CardAdmFee);
          });
          setTicket(res.retorno.ticket);
          // setAmauntByProduct(product);
          // setAmauntMonth(list);
          setTotalAmauntCards(totCard);
          setTotalAmaunTransfer(totTransfer);
          setTotalAmaunt(totCard + totTransfer + cardRate + transferRate);
          setPieChartDivider([
            { name: 'Element', value: cardRate + transferRate },
            {
              name: 'Volume Global',
              value: totCard + totTransfer,
            },
          ]);
          setTotalTransferRate(transferRate);
          setTotalCardRate(cardRate);

          //
          setAmauntByProductDay(product);
          setAmauntDay(list);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));

      api.order
        .summaryTopFive(monthSelected, yearSelected, 'month', cancel)
        .then(res => {
          const list = [];
          res.retorno.forEach(r => {
            list.push({
              company: r.company,
              order: r.orders,
              amount: r.amount,
            });
          });
          setTopFive(list);
        })
        .catch(err => console.log(err));
    } else {
      api.order
        .summary(monthSelected, yearSelected, 'month', cancel)
        .then(res => {
          const list = [];
          const pie = [];
          let tot = 0.0;
          res.retorno.forEach(r => {
            list.push({
              name: moment(r.date).format('MMMM'),
              pedidos: r.orders,
            });
            pie.push({
              name: r.date,
              value: r.orders,
            });
            tot += parseFloat(r.orders);
          });
          pie.sort(function (a, b) {
            return b.value - a.value;
          });
          setDataMonth(list);
          setPieChartMonth(pie);
          setTotalOrders(tot);
        })
        .catch(err => console.log(err));

      api.order
        .summaryTopFive(monthSelected, yearSelected, 'month', cancel)
        .then(res => {
          const list = [];
          res.retorno.forEach(r => {
            list.push({
              company: r.company,
              order: r.orders,
              amount: r.amount,
            });
          });
          setTopFive(list);
        })
        .catch(err => console.log(err));

      api.order
        .summaryItems(monthSelected, yearSelected, 'month', cancel)
        .then(res => {
          const list = [];
          const product = [];
          let totCard = 0.0;
          let totTransfer = 0.0;
          let transferRate = 0.0;
          let cardRate = 0.0;
          res.retorno.list.forEach(r => {
            list.push({
              name: moment(r.date).format('MMMM'),
              amount: r.amount,
            });
            product.push({
              name: moment(r.date).format('MMMM'),
              card: r.card,
              transfer: r.transfer,
            });
            totCard += parseFloat(r.card);
            totTransfer += parseFloat(r.transfer);
            transferRate += parseFloat(r.TransferAdmFee);
            cardRate += parseFloat(r.CardAdmFee);
          });
          setTicket(res.retorno.ticket);
          setAmauntByProduct(product);
          setAmauntMonth(list);
          setTotalAmauntCards(totCard);
          setTotalAmaunTransfer(totTransfer);
          setTotalAmaunt(totCard + totTransfer + cardRate + transferRate);
          setPieChartDivider([
            { name: 'Element', value: cardRate + transferRate },
            {
              name: 'Volume Global',
              value: totCard + totTransfer,
            },
          ]);
          setTotalTransferRate(transferRate);
          setTotalCardRate(cardRate);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }

    api.cards
      .summary(monthSelected, yearSelected, cancel)
      .then(res => {
        setTotalCards(res.retorno);
      })
      .catch(err => console.log(err));
  };

  const searchFiltered = () => {
    if (monthSelected) {
      if (yearSelected) {
        setDayVisible(true);
        setMonthVisible(false);
        searchSpecific();
      } else {
        notifyError('Informe o ano');
      }
    } else if (yearSelected) {
      searchSpecific();
      setDayVisible(false);
      setMonthVisible(true);
    } else {
      notifyError('Informe um mês e ano ou apenas ano para busca.');
    }
  };

  useEffect(() => {
    moment.locale('pt');

    if (
      api.currentUser.id !== '8' &&
      api.currentUser.id !== '9' &&
      api.currentUser.id !== '278' &&
      api.currentUser.id !== '214' &&
      api.currentUser.id !== '215' &&
      api.currentUser.id !== '255'
    ) {
      history.push(routing.dashboard);
    }

    searchDefault();
  }, []);

  const cleanFilter = () => {
    setMonth(null);
    setYear(null);
    setDayVisible(true);
    setMonthVisible(true);
    searchDefault();
  };

  return (
    <>
      <Loading
        open={loading}
        msg="Aguarde, estamos preparando as métricas...."
      />
      <Grid container spacing={3} style={{ marginTop: '50px' }}>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Painel</h4>
          <div style={{ display: 'flex' }}>
            <Select
              styles={customStyles1}
              classNamePrefix="select"
              placeholder="Mês"
              isSearchable={false}
              options={monthOpt}
              onChange={setMonth}
              value={monthSelected}
              menuPortalTarget={document.body}
            />
            <Select
              styles={customStyles1}
              classNamePrefix="select"
              placeholder="Ano"
              isSearchable={false}
              options={yearOpt}
              onChange={setYear}
              value={yearSelected}
              menuPortalTarget={document.body}
              style={{ margin: '10px' }}
            />
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{ margin: '10px' }}
              onClick={searchFiltered}
            >
              Buscar
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{ margin: '10px' }}
              onClick={cleanFilter}
            >
              Limpar
            </Button>
          </div>
        </div>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    CARTÕES ATIVOS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaWallet />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat().format(totalCards)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    CARGAS - CARTÕES
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h5" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalAmauntCards)}
                  </Typography>
                  <Typography variant="p" color="inherit">
                    Ticket médio:{' '}
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(ticket)}
                  </Typography>
                </Grid>
                {/* <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    CARGAS - TRANSFERÊNCIAS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalAmauntTransfer)}
                  </Typography>
                </Grid>
                {/* <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    TOTAL DE PEDIDOS (CARGA)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaChartBar />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat().format(totalOrders)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    VOLUME FINANCEIRO
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalAmaunt)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    TAXAS ADM CARTÕES
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalCardRate)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    TAXAS ADM TRANSFERÊNCIAS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalTransferRate)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    TOTAL DE TAXAS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalCardRate + totalTransferRate)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle2" color="inherit">
              VOLUME FINANCEIRO NO ANO
            </Typography>
            <Typography variant="h4" color="inherit">
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(totalAmaunt)}
            </Typography>
          </div>
        </Grid> */}
        {dayVisible && (
          <>
            <Grid item xs={12} md={8}>
              <div className={stylesDash.longWidget}>
                <Typography variant="h6" color="inherit">
                  Quantidade de Pedidos (dia)
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    width={500}
                    height={300}
                    data={dataDay}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pedidos" name="Pedidos" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={stylesDash.longWidget}>
                <Typography variant="h6" color="inherit">
                  Melhor dia do mês
                </Typography>
                <ResponsiveContainer width="100%" height={260}>
                  <PieChart width={200} height={200}>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={pieChartData}
                      innerRadius={60}
                      fill="#2898FF"
                      outerRadius={80}
                      dataKey="value"
                      onMouseEnter={(e, id) => setActiveIndexId(id)}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </>
        )}

        {monthVisible && (
          <>
            <Grid item xs={12} md={8}>
              <div className={stylesDash.longWidget}>
                <Typography variant="h6" color="inherit">
                  Quantidade de Pedidos (Ano)
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    width={500}
                    height={300}
                    data={dataMonth}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <XAxis dataKey="name" /* angle={-35} textAnchor="end" */ />
                    <YAxis />
                    <Area
                      dataKey="pedidos"
                      name="Pedidos"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                    <Tooltip />
                    <Legend />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={stylesDash.longWidget}>
                <Typography variant="h6" color="inherit">
                  Melhor mês do ano
                </Typography>
                <ResponsiveContainer width="100%" height={260}>
                  <PieChart width={200} height={200}>
                    <Pie
                      activeIndex={activeIndexMonth}
                      activeShape={renderActiveShapeMonth}
                      data={pieChartmonth}
                      innerRadius={60}
                      fill="#2898FF"
                      outerRadius={80}
                      dataKey="value"
                      onMouseEnter={(e, id) => setActiveIndexIdMonth(id)}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </>
        )}

        {dayVisible && (
          <>
            <Grid item xs={12} md={6}>
              <div className={stylesDash.longWidget}>
                <Typography variant="h6" color="inherit">
                  Volume financeiro total movimentado (Dia)
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    width={500}
                    height={300}
                    data={amauntDay}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={DataFormater} />
                    <Area
                      dataKey="amount"
                      name="Volume Financeiro"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                    <Tooltip
                      formatter={value =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(value)
                      }
                    />
                    <Legend />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={stylesDash.longWidget}>
                <Typography variant="h6" color="inherit">
                  Volume financeiro Cartão & Transferência (Dia)
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    width={500}
                    height={400}
                    data={amauntByProductDay}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={DataFormater} />
                    <Legend />
                    <Tooltip
                      formatter={value =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(value)
                      }
                    />
                    <Line
                      type="monotone"
                      dataKey="transfer"
                      name="Transferência"
                      stackId="1"
                      stroke="#ffc658"
                      fill="#ffc658"
                    />
                    <Line
                      type="monotone"
                      dataKey="card"
                      name="Cartão"
                      stackId="1"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                      label={CustomizedLabel}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </>
        )}

        {monthVisible && (
          <Grid item xs={12} md={8}>
            <div className={stylesDash.longWidget}>
              <Typography variant="h6" color="inherit">
                Volume financeiro total movimentado (Ano)
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  width={500}
                  height={300}
                  data={amauntMonth}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={DataFormater} />
                  <Area
                    dataKey="amount"
                    name="Volume Financeiro"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                  <Tooltip
                    formatter={value =>
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(value)
                    }
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={dayVisible && dayVisible !== monthVisible ? 12 : 4}
        >
          <div className={stylesDash.longWidget}>
            <Typography variant="h6" color="inherit">
              Volume Global X Faturamento Element
            </Typography>
            <ResponsiveContainer width="100%" height={260}>
              <PieChart width={200} height={200}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShapeD}
                  data={pieChartDivider}
                  innerRadius={60}
                  fill="#2898FF"
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={(e, id) => setActiveIndexId(id)}
                >
                  {pieChartDivider.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        {monthVisible && (
          <Grid item xs={12} md={12}>
            <div className={stylesDash.longWidget}>
              <Typography variant="h6" color="inherit">
                Volume financeiro Cartão & Transferência (Ano)
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  width={500}
                  height={400}
                  data={amauntByProduct}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={DataFormater} />
                  <Tooltip
                    formatter={value =>
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(value)
                    }
                  />
                  <Legend />
                  {/* <Area
                  type="monotone"
                  dataKey="uv"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                /> */}
                  <Line
                    type="monotone"
                    dataKey="transfer"
                    name="Transferência"
                    stackId="1"
                    stroke="#ffc658"
                    fill="#ffc658"
                    // label={CustomizedLabel}
                  />
                  <Line
                    type="monotone"
                    dataKey="card"
                    name="Cartão"
                    stackId="1"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                    label={CustomizedLabel}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {/* <div className={stylesDash.longWidget}> */}
          <Typography variant="h6" color="inherit" style={{ marginBottom: 15 }}>
            5 maiores clientes
          </Typography>
          <TopFiveTale docs={topFive} />
          {/* <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={topFive}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="company" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="amount" name="Financeiro" fill="#8884d8" />
                <Bar dataKey="order" name="Pedidos" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer> */}
          {/* </div> */}
        </Grid>

        <Grid item xs={12} md={12}>
          {/* <div className={stylesDash.longWidget}> */}
          <Typography variant="h6" color="inherit" style={{ marginBottom: 15 }}>
            Contratos pendentes de assinatura ({fourSign.length} contratos)
          </Typography>
          <Contratos4Sign docs={fourSign} />
          {/* <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={topFive}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="company" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="amount" name="Financeiro" fill="#8884d8" />
                <Bar dataKey="order" name="Pedidos" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer> */}
          {/* </div> */}
        </Grid>
      </Grid>
    </>
  );
}
