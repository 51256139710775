import axios from 'axios';
import moment from 'moment/moment';
import axiosConfig from './axiosConfig';

import apiRoutes from './axiosRouting';
import storage from './storage';

const jwt = require('jsonwebtoken');

// import {deserializeApiError, ApiInternalServerError} from '../error';

function getDataFromSession(token) {
  const data = {};
  if (token) {
    try {
      const session = jwt.decode(token);
      data.user = session;
    } catch (err) {
      console.debug(err);
    }
  }
  return data;
}

// eslint-disable-next-line no-unused-vars
const cleanValue = vl => {
  if (vl) {
    const res = vl?.replace('R$', ' ');
    const final = res?.replaceAll(',', '.');
    return final?.trim();
  }
  return vl;
};

const cleanCEP = dt => {
  const res = dt.replace('-', '');
  const final = res.replaceAll('.', '');
  return final.trim();
};

function storeSessionData(token) {
  storage.auth.token = token;
}

/**
 * @param {AbortController} cancel
 */
function cancelToken(cancel) {
  return new axios.CancelToken(interrupt => {
    cancel.signal.addEventListener('abort', e => {
      interrupt(e);
    });
  });
}

function throwFormattedError(error) {
  if (error.response) {
    const { data } = error.response;
    if (data) {
      if (data.code === 'API_ERR_EXPIRED_SESSION_ERROR') {
        storage.auth.token = null;
        storage.auth.projectClass = null;
        storage.auth.projectDetails = null;
        // window.location.href = '/signin';
      }
      if (data.status === 401) {
        storage.auth.token = null;
        storage.auth.projectClass = null;
        storage.auth.projectDetails = null;
      }
      // if (
      //     error.response.status === 500 &&
      //     data.code !== ApiInternalServerError.ERROR_CODE
      // ) {
      //     throw new ApiInternalServerError();
      // }
      // const apiError = deserializeApiError(data);
      throw data;
    }
  }
  if (error.message === 'Network Error') {
    throw new Error('Falha na requisição');
  }
  throw error;
}

// function returnText(data) {
//   // let result = data.replaceAll('&', '%26');
//   let result = result.replaceAll('@', '%40');
//   result = result.replaceAll('%', '%25');
//   result = data.replaceAll('&', '%26');
//   return result;
// }
class Api {
  constructor() {
    this.axios = axios.create(axiosConfig);
    this.axiosgm = axios.create({
      baseURL: 'https://fchev.sisgm.com/',
    });

    this.currentSession = storage.auth.token;
    const { user } = getDataFromSession(this.currentSession);
    this.currentUser = user;

    // this.currentProject = getDataFromStorage();

    this.currentAuthorizationHeader = this.currentSession
      ? `Bearer ${this.currentSession}`
      : '';

    this.axios.interceptors.request.use(request => {
      if (this.currentSession) {
        request.headers.Authorization = this.currentAuthorizationHeader;
      }
      return request;
    });

    this.axiosgm.interceptors.request.use(request => {
      request.headers.Authorization =
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiR2xheWRzb24gQmVydG96emkgTGltYSAiLCJjcGYiOiIzMjY1MjIxMDgzMSIsImVtYWlsIjoiZ2xheWRzb25AaXRmYXN0LmNvbS5iciIsInVpZCI6Ijk3Y2U0YmMyLTIxOTItNDViOS1hZmU2LTRhNmFiNGM3NDU1YSIsImNlbGxQaG9uZSI6Iis1NTEyOTk2MDIzMzY2IiwidHdvRmFUeXBlIjoic21zIiwicG9zdGFsQ29kZSI6IiIsImJpcnRoZGF5IjoiIiwiQWNjZXNzVHlwZXMiOlsiZ2V0VXNlciIsInVwZGF0ZVVzZXIiLCJhbGwiLCJnZW5lcmF0ZVFyQ29kZSJdLCJ1c2VyVHlwZSI6IkNMSUVOVCIsImlhdCI6MTY4OTE2NDUxMH0.kAeWo1-7HsLLVabwFQf8ZsksjK8q0icOVj1-BHKF17A';
      return request;
    });

    const setSession = token => {
      this.currentSession = token;
      this.currentAuthorizationHeader = token ? `Bearer ${token}` : '';
      const result = getDataFromSession(token);
      this.currentUser = result.user;

      storeSessionData(token);
    };

    // eslint-disable-next-line no-unused-expressions
    this.language = {
      set: async language => {
        // storage.auth.language = language;
        localStorage.setItem('language', language);
        return 'ok';
      },
      get: async () => localStorage.getItem('language'),
    };

    this.mySession = {
      set: async token => {
        try {
          setSession(token);
          return 'ok';
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.notification = {
      getmy: async (status, orderBy, search, page, itens) => {
        let ordernation = '';
        const listFilters = [];
        if (status) listFilters.push({ filter: 'status', filterValue: status });

        if (search)
          listFilters.push({ filter: 'subject', filterValue: search });

        if (orderBy !== '') ordernation = `&orderBy=${orderBy}`;

        const data = JSON.stringify(listFilters);
        try {
          const response = await this.axios.get(
            `${apiRoutes.notification}/sender/${this.currentUser.id}?limit=${itens}&page=${page}&filters=${data}${ordernation}`,
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      send: async (
        subject,
        message,
        emails,
        doc,
        sendCredential,
        systemLink,
        userBase,
      ) => {
        const formData = new FormData();

        formData.append('send_user_id', Number(this.currentUser.id));
        formData.append('subject', subject);
        formData.append('message', message);
        formData.append('send_credencial', sendCredential);
        formData.append('user_base', userBase);
        formData.append('page_url', systemLink);
        formData.append('email_list', JSON.stringify(emails));

        if (doc) formData.append('doc', doc);

        try {
          const response = await this.axios.post(
            `${apiRoutes.notification}/`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      delete: async notificationId => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.notification}/${notificationId}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      linkUserToGroup: async (userId, groupId) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.notification}/groupItem`,
            {
              user_id: userId,
              group_id: groupId,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      newgroup: async name => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.notification}/group`,
            { group_title: name },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getgroup: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.notification}/groups`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      sendCredentials: async list => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.user}/batch/send/credentials`,
            {
              usersId: list,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.service = {
      getAll: async () => {
        try {
          const response = await this.axios.get(`${apiRoutes.service}`);
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      update: async (serviceId, status) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.service}/set/${serviceId}`,
            {
              status,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.joycode = {
      new: async (
        contractId,
        reportInterval,
        invoiceType,
        overdueInvoice,
        isTransferPayment,
        status,
        typeDays,
        description,
      ) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.joycode}/config`,
            {
              contract_id: contractId,
              report_interval: reportInterval,
              invoice_type: invoiceType,
              overdue_invoice: overdueInvoice,
              is_transfer_payment: isTransferPayment === 'true',
              status,
              type_day: typeDays,
              description,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      update: async (
        id,
        contractId,
        reportInterval,
        invoiceType,
        overdueInvoice,
        isTransferPayment,
        status,
        typeDays,
        description,
      ) => {
        console.log({
          contract_id: contractId,
          report_interval: reportInterval,
          invoice_type: invoiceType,
          overdue_invoice: overdueInvoice,
          is_transfer_payment: isTransferPayment,
          status,
          type_day: typeDays,
          description,
        });
        try {
          const response = await this.axios.put(
            `${apiRoutes.joycode}/config/${id}`,
            {
              contract_id: contractId,
              report_interval: reportInterval,
              invoice_type: invoiceType,
              overdue_invoice: overdueInvoice,
              is_transfer_payment: isTransferPayment,
              status,
              type_day: typeDays,
              description: description === '' ? ' ' : description,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getConf: async (product, page, limit, extra, status, search, cancel) => {
        const listFilters = [];
        console.log(status);
        if (status) listFilters.push({ filter: 'status', filterValue: status });
        if (product)
          listFilters.push({ filter: 'product', filterValue: product });

        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'datePayment'
                ? extra.date
                : encodeURIComponent(extra.label),
          });

        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'datePayment'
                  ? s.date
                  : encodeURIComponent(s.label),
            });
          });
        }
        const data = JSON.stringify(listFilters);
        console.log(
          `${apiRoutes.joycode}/config?page=${page}&limit=${limit}&filters=${data}`,
        );
        try {
          const response = await this.axios.get(
            `${apiRoutes.joycode}/config?page=${page}&limit=${limit}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      get: async (extra, search, status, itens, page, product, cancel) => {
        try {
          const listFilters = [];
          if (status)
            listFilters.push({ filter: 'status', filterValue: status });
          if (product)
            listFilters.push({ filter: 'product', filterValue: product });

          if (extra)
            listFilters.push({
              filter: extra.byType,
              filterValue:
                extra.byType === 'date'
                  ? extra.date
                  : encodeURIComponent(extra.label),
            });

          if (search.length > 0) {
            search.forEach(s => {
              listFilters.push({
                filter: s.byType,
                filterValue:
                  s.byType === 'date' ? s.date : encodeURIComponent(s.label),
              });
            });
          }

          const data = JSON.stringify(listFilters);

          const response = await this.axios.get(
            `${apiRoutes.joycode}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.user = {
      login: async (email, password) => {
        console.log(`${apiRoutes.user}/login`);
        try {
          const response = await this.axios.post(`${apiRoutes.user}/login`, {
            email,
            password,
          });
          // setSession(response.data.token);
          // console.log(response);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      verify2fa: async (resolve, userToken, verificationId, email) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.user}/login/2fa`,
            {
              resolver: resolve,
              userToken,
              verificationId,
              email,
            },
          );
          setSession(response.data.token);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      verify2fa2: async (
        type,
        userToken /* , verificationId, */,
        email,
        userId,
      ) => {
        console.log(type, userToken /* , verificationId, */, email, userId);
        try {
          const response = await this.axios.post(
            `${apiRoutes.user}/login/verify/2fa`,
            {
              // resolver: resolve,
              type,
              code: userToken,
              // verificationId,
              email,
              userId,
            },
          );
          setSession(response.data.token);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      sen2Fa: async (type, userId) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.user}/login/send/2fa`,
            { type, userId },
          );
          // setSession(response.data.token);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      enterbyemail: async (email, password) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.user}/login/linkEmail`,
            {
              email,
              password,
            },
          );
          setSession(response.data.token);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      resetPassword: async email => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.user}/login/resetpwd`,
            {
              email,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      create: async (
        twoFaType,
        name,
        email,
        cpf,
        phone,
        mobile,
        userType,
        permission,
      ) => {
        try {
          const response = await this.axios.post(`${apiRoutes.user}/`, {
            name,
            email,
            cpf,
            phone,
            mobile,
            userType,
            twoFaType,
            permission,
          });

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAll: async (orderBy, search, status, itens, page, cancel) => {
        // const response = await this.axios.get(`${apiRoutes.order}
        // ?${query}limit=${itens}&page=${page}`, {

        let order = '';
        if (orderBy !== '') order = `&orderBy=${orderBy}`;
        let query = '';
        if (status !== '') query = `filter=status&filterValue=${status}&`;
        if (search) query = `filter=name&filterValue=${search}&`;
        const response = await this.axios.get(
          `${apiRoutes.user}?${query}limit=${itens}&page=${page}${order}`,
          {
            cancelToken: cancelToken(cancel),
          },
        );
        return response.data;
      },
      getAllSimple: async (orderBy, search, status, itens, page, cancel) => {
        // const response = await this.axios.get(`${apiRoutes.order}
        // ?${query}limit=${itens}&page=${page}`, {

        console.log(orderBy, search, status, itens, page, cancel);
        let order = '';
        if (orderBy !== '') order = `&orderBy=${orderBy}`;
        let query = '';
        if (status !== '') query = `filter=status&filterValue=${status}&`;
        if (search) query = `filter=name&filterValue=${search}&`;
        const response = await this.axios.get(
          `${apiRoutes.user}/simple?${query}limit=${itens}&page=${page}${order}`,
          {
            cancelToken: cancelToken(cancel),
          },
        );
        console.log(response);
        return response.data;
      },
      getEmails: async cancel => {
        const response = await this.axios.get(`${apiRoutes.user}/list/emails`, {
          cancelToken: cancelToken(cancel),
        });
        return response.data;
      },
      update: async data => {
        const { email, name, cpf, department, phone, mobile } = data;
        const response = await this.axios.get(`${apiRoutes.user}/`, {
          email,
          name,
          cpf,
          department,
          phone,
          mobile,
        });
        return response.data;
      },
      getById: async (id, cancel) => {
        const response = await this.axios.get(`${apiRoutes.user}/${id}`, {
          cancelToken: cancelToken(cancel),
        });
        return response.data;
      },
      editPermission: async (userId, permissions) => {
        const response = await this.axios.put(
          `${apiRoutes.user}/permissions/${userId}`,
          {
            permissions,
          },
        );
        return response.data;
      },
      editUser: async (
        email,
        cpf,
        isActive,
        mobile,
        phone,
        twoFaType,
        typeUser,
      ) => {
        const response = await this.axios.put(`${apiRoutes.user}/`, {
          email,
          is_active: isActive,
          cpf,
          phone,
          mobile,
          twoFaType,
          role_id: typeUser,
        });
        return response.data;
      },
    };
    this.statement = {
      get: async (extra, chipData, bankSelected, cancel, itens, page) => {
        // const filtersList = [];
        let query = '';

        if (extra) {
          if (extra.byType === 'ini' || extra.byType === 'end') {
            query += `&${extra.byType}=${moment(extra.date).format(
              'YYYY-MM-DD',
            )}`;
          } else {
            query += `&${extra.byType}=${extra.label}`;
          }
        }

        if (bankSelected.value !== '') {
          query += `&bank=${bankSelected.value}`;
        }

        if (chipData.length > 0) {
          chipData.forEach(c => {
            if (c.byType === 'ini' || c.byType === 'end') {
              query += `&${c.byType}=${moment(c.date).format('YYYY-MM-DD')}`;
            } else {
              query += `&${c.byType}=${c.label}`;
            }
          });
        }
        try {
          const response = await this.axios.get(
            `${apiRoutes.statement}?limit=${itens}&page=${page}${query}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      update: async (id, bankAccountId, paymentDate) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.statement}/${id}`,
            {
              bankAccountId,
              paymentDate,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.banks = {
      new: async (
        company,
        bank,
        branchNumber,
        accountNumber,
        cnabCompanyName,
        cnabBankName,
      ) => {
        try {
          const response = await this.axios.post(`${apiRoutes.banks}`, {
            company_id: company?.value,
            bank: bank.label,
            branch_number: branchNumber,
            account_number: accountNumber,
            account_holder: company.label,
            cnab_company_name: cnabCompanyName,
            cnab_bank_name: cnabBankName,
            bank_code: bank.value,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      edit: async (
        id,
        company,
        bank,
        branchNumber,
        accountNumber,
        cnabCompanyName,
        cnabBankName,
        status,
      ) => {
        try {
          const response = await this.axios.put(`${apiRoutes.banks}/${id}`, {
            company_id: company?.value,
            bank: bank.label,
            branch_number: branchNumber,
            account_number: accountNumber,
            account_holder: company.label,
            cnab_company_name: cnabCompanyName,
            cnab_bank_name: cnabBankName,
            bank_code: bank.value,
            status,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      get: async (cancel, query = '') => {
        try {
          const response = await this.axios.get(`${apiRoutes.banks}${query}`, {
            cancelToken: cancelToken(cancel),
          });
          console.log(`${apiRoutes.banks}${query}`);
          console.log(response.data);
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.company = {
      get: async (cancel, limit, page, status, extra, search) => {
        const listFilters = [];
        if (status !== '')
          listFilters.push({ filter: 'is_active', filterValue: status });

        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'datePayment'
                ? extra.date
                : encodeURIComponent(extra.label),
          });

        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'datePayment'
                  ? s.date
                  : encodeURIComponent(s.label),
            });
          });
        }

        const data = JSON.stringify(listFilters);
        // console.log(
        //   `${apiRoutes.company}?limit=${limit}&page=${page}&filters=${data}`,
        // );
        try {
          const response = await this.axios.get(
            `${apiRoutes.company}?limit=${limit}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      new: async (
        companyName,
        cnpj,
        im,
        ie,
        address,
        number,
        uf,
        postalCode,
        complement,
        prefix,
        district,
        city,
        isActive,
        certName,
      ) => {
        try {
          const response = await this.axios.post(`${apiRoutes.company}/`, {
            company_name: companyName,
            cnpj,
            im,
            ie,
            street_name: address,
            number,
            state: uf,
            postal_code: postalCode,
            complement,
            prefix,
            district,
            city,
            is_active: isActive,
            cert_file_name: certName,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      edit: async (
        id,
        companyName,
        cnpj,
        im,
        ie,
        address,
        number,
        uf,
        postalCode,
        complement,
        prefix,
        district,
        city,
        isActive,
        certName,
      ) => {
        try {
          const response = await this.axios.put(`${apiRoutes.company}/${id}`, {
            company_name: companyName,
            cnpj,
            im,
            ie,
            street_name: address,
            number,
            state: uf,
            postal_code: postalCode,
            complement,
            prefix,
            district,
            city,
            is_active: isActive,
            cert_file_name: certName,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.gm = {
      get: async (
        extra,
        search,
        status,
        itens,
        page,
        orderBy,
        product,
        cancel,
      ) => {
        try {
          let ordernation = '';
          const listFilters = [];
          if (status)
            listFilters.push({ filter: 'status', filterValue: status });
          if (product)
            listFilters.push({ filter: 'product', filterValue: product });

          if (extra)
            listFilters.push({
              filter: extra.byType,
              filterValue:
                extra.byType === 'datePayment'
                  ? extra.date
                  : encodeURIComponent(extra.label),
            });

          if (search.length > 0) {
            search.forEach(s => {
              listFilters.push({
                filter: s.byType,
                filterValue:
                  s.byType === 'datePayment'
                    ? s.date
                    : encodeURIComponent(s.label),
              });
            });
          }

          if (orderBy !== '') ordernation = `&orderBy=${orderBy}`;
          const data = JSON.stringify(listFilters);

          if (api.currentUser?.user?.role?.name === 'GerenteLoja') {
            const response = await this.axios.get(
              `${apiRoutes.gm}/orders/pedro?limit=${itens}&page=${page}&filters=${data}${ordernation}`,
              {
                cancelToken: cancelToken(cancel),
              },
            );
            return response.data;
          }
          const response = await this.axios.get(
            `${apiRoutes.gm}/orders?limit=${itens}&page=${page}&filters=${data}${ordernation}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      access: async (extra, chipData, itens, page, cancel) => {
        const listFilters = [];
        console.log(extra, chipData, itens, page, cancel);

        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'lastLogin'
                ? extra.date
                : encodeURIComponent(extra.label),
          });

        if (chipData.length > 0) {
          chipData.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'lastLogin' ? s.date : encodeURIComponent(s.label),
            });
          });
        }

        console.log(listFilters);
        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axiosgm.get(
            `${apiRoutes.user}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          // setSession(response.data.token);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.dcp = {
      get: async (statusSelected, extra, itens, page, cancel, search) => {
        try {
          const listFilters = [];
          if (statusSelected !== '')
            listFilters.push({ filter: 'status', filterValue: statusSelected });
          if (extra)
            listFilters.push({
              filter: extra.byType,
              filterValue:
                extra.byType === 'datePayment'
                  ? moment(extra.date).format('YYYY-MM-DD')
                  : extra.label,
            });
          if (search.length > 0) {
            search.forEach(s => {
              listFilters.push({
                filter: s.byType,
                filterValue:
                  s.byType === 'datePayment'
                    ? moment(s.date).format('YYYY-MM-DD')
                    : s.label,
              });
            });
          }

          const data = JSON.stringify(listFilters);
          const response = await this.axios.get(
            `${apiRoutes.dcp}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      sendEmail: async (email, id) => {
        try {
          const response = await this.axios.post(`${apiRoutes.dcp}/sendemail`, {
            email,
            id,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      extern: async (email, document) => {
        try {
          const response = await this.axios.post(`${apiRoutes.dcp}/`, {
            email,
            document,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getPaymentStatus: async id => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.dcp}/${id}/status`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.order = {
      get: async (
        extra,
        search,
        status,
        itens,
        page,
        orderBy,
        product,
        cancel,
      ) => {
        try {
          let ordernation = '';
          const listFilters = [];
          if (status)
            listFilters.push({ filter: 'status', filterValue: status });
          if (product)
            listFilters.push({ filter: 'product', filterValue: product });

          if (extra)
            listFilters.push({
              filter: extra.byType,
              filterValue:
                extra.byType === 'datePayment'
                  ? extra.date
                  : encodeURIComponent(extra.label),
            });

          if (search.length > 0) {
            search.forEach(s => {
              listFilters.push({
                filter: s.byType,
                filterValue:
                  s.byType === 'datePayment'
                    ? s.date
                    : encodeURIComponent(s.label),
              });
            });
          }

          if (orderBy !== '') ordernation = `&orderBy=${orderBy}`;
          const data = JSON.stringify(listFilters);

          const response = await this.axios.get(
            `${apiRoutes.order}?limit=${itens}&page=${page}&filters=${data}${ordernation}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getNfServices: async cancel => {
        try {
          const response = await this.axios.get(`${apiRoutes.servicecodes}`, {
            cancelToken: cancelToken(cancel),
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      summary: async (month, year, type, cancel) => {
        let query = '';
        if (month) {
          query += `&month=${month.value}`;
        }
        if (year) {
          query += `&year=${year.value}`;
        }
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/summary?type=${type}${query}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      summaryItems: async (month, year, type, cancel) => {
        let query = '';
        if (month) {
          query += `&month=${month.value}`;
        }
        if (year) {
          query += `&year=${year.value}`;
        }
        try {
          const response = await this.axios.get(
            `${apiRoutes.orderItems}/summary?type=${type}${query}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      summaryTopFive: async (month, year, type, cancel) => {
        let query = '';
        if (month) {
          query += `&month=${month.value}`;
        }
        if (year) {
          query += `&year=${year.value}`;
        }
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/summary/topfive?type=${type}${query}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      summaryClient: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/summary/ticket`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      details: async (orderId, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/details/${orderId}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getItems: async (
        itemId,
        extra,
        orderBy,
        chipData,
        status,
        itens,
        page,
        cancel,
      ) => {
        const listFilters = [];
        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'created_at'
                ? extra.date
                : encodeURIComponent(extra.label),
          });
        if (status) listFilters.push({ filter: 'status', filterValue: status });

        // if (search) listFilters.push({ filter: 'name', filterValue: search });

        // if (orderBy !== '') ordernation = `&orderBy=${orderBy}`;

        if (chipData.length > 0) {
          chipData.forEach(c => {
            listFilters.push({ filter: c.byType, filterValue: c.label });
          });
        }
        // const data = JSON.stringify(list);

        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/items/${itemId}?limit=${itens}&page=${page}&filters=${data}`,

            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getItemsByOrderId: async (orderId, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/items/byOrderId/${orderId}`,

            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      editOrder: async (
        orderId,
        orderName,
        nfComments,
        paymentDate,
        nf,
        boleto,
      ) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.order}/${orderId}`,
            {
              is_invoicer_legal_entity: nf,
              orderName,
              payment_date: boleto ? paymentDate : '',
              comments: nfComments,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      newOrder: async (
        typeCard,
        contractId,
        cnpj,
        orderName,
        nfComments,
        paymentDate,
        nf,
        boleto,
        customizedCard,
      ) => {
        try {
          console.log('NOVO PEDIDO')
          console.log({
            virtual: typeCard === 'virtualCard' ? 1 : 0,
            contract_id: contractId,
            is_invoicer_legal_entity: nf !== 'ND',
            is_nd: nf === 'NFND',
            order_name: orderName,
            payment_date: boleto ? paymentDate : '',
            cnpj,
            comments: nfComments,
            customizedCard,
          })
          const response = await this.axios.post(`${apiRoutes.order}`, {
            virtual: typeCard === 'virtualCard' ? 1 : 0,
            contract_id: contractId,
            is_invoicer_legal_entity: nf !== 'ND',
            is_nd: nf === 'NFND',
            order_name: orderName,
            payment_date: boleto ? paymentDate : '',
            cnpj,
            comments: nfComments,
            customizedCard,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      newOrderSingleinvoice: async (
        contractId,
        cnpj,
        orderName,
        nfComments,
        paymentDate,
        // nf,
        boleto,
        serviceCodeId,
        serviceValue,
      ) => {
        try {
          console.log(
            contractId,
            cnpj,
            orderName,
            nfComments,
            paymentDate,
            // nf,
            boleto,
            serviceCodeId,
            serviceValue,
          );
          const response = await this.axios.post(
            `${apiRoutes.order}/singleinvoice`,
            {
              contract_id: contractId,
              // is_invoicer_legal_entity: nf,
              order_name: orderName,
              payment_date: boleto ? paymentDate : '',
              cnpj,
              comments: nfComments,
              service_code_id: serviceCodeId,
              service_value: serviceValue,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      payment: async (orderId, paymentDate, bankAccountId) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/payment/${orderId}`,
            {
              paymentDate,
              bankAccountId,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      addProvision: async (orderId, value, catProd) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/item/unit`,
            {
              orderId,
              product_type: catProd === 'pre' ? 'Card' : 'Transfer',
              product: 'Provision',
              units: 1,
              value,
              is_individual: true,
              phone: null,
              mobile: null,
              email: null,
              postal_code: null,
              street_name: null,
              number: null,
              complement: null,
              district: null,
              city: null,
              state: null,
              bank_number: null,
              branch_number: null,
              branch_number_verifier: null,
              account_type: null,
              account_number: null,
              account_number_verifier: null,
              cpf: '83327843791',
              name: 'PEDRO DE MONTENEGRO CORREA FRAGA',
              birthdate: '2022-3-24',
              mothers_name: null,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      addStoque: async (orderId, units) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/item/unit`,
            {
              orderId,
              product_type: 'Card',
              product: 'CardStock',
              units,
              value: '8.50',
              is_individual: true,
              phone: null,
              mobile: null,
              email: null,
              postal_code: null,
              street_name: null,
              number: null,
              complement: null,
              district: null,
              city: null,
              state: null,
              bank_number: null,
              branch_number: null,
              branch_number_verifier: null,
              account_type: null,
              account_number: null,
              account_number_verifier: null,
              cpf: '83327843791',
              name: 'PEDRO DE MONTENEGRO CORREA FRAGA',
              birthdate: '2022-3-24',
              mothers_name: null,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      addVinc: async (orderId, individuals) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/item/IndividualList`,
            {
              orderId,
              individuals,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      addItemsDcp: async (
        individuals,
        orderId,
        productType,
        baseYear,
        allContract,
      ) => {
        if (allContract > 0) {
          const findIndex = individuals.findIndex(f => f.id === 'all');
          if (findIndex !== -1) {
            individuals.splice(findIndex, 1);
          }
          const findIndex1 = individuals.findIndex(f => f.id === 'all');
          if (findIndex1 !== -1) {
            individuals.splice(findIndex1, 1);
          }
        }

        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/item/unitdcp`,
            {
              orderId,
              productType,
              baseYear,
              individuals,
              allContract,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      addItems: async (file, orderId, ignoreError) => {
        const formData = new FormData();
        let query = '';
        if (ignoreError) {
          query = '?ignoreError=true';
        }
        formData.append('orderId', orderId);
        formData.append('doc', file);
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/item${query}`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      removeItems: async listItens => {
        const data = JSON.stringify(listItens);
        try {
          const response = await this.axios.delete(
            `${apiRoutes.order}/remove/item?listItens=${data}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      removeItemsForOrder: async orderId => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.orderItems}/${orderId}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      complete: async orderId => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/complete`,
            {
              orderId,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      cancel: async orderId => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/cancel/${orderId}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      duplicate: async orderId => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/duplicate/${orderId}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAdmin: async (itens, page, status, search, extra) => {
        const listFilters = [];
        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'created_at'
                ? extra.date
                : encodeURIComponent(extra.label),
          });
        if (status !== '')
          listFilters.push({ filter: 'status', filterValue: status });
        // if (order)
        //   listFilters.push({ filter: 'orderNumber', filterValue: order });
        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({ filter: s.byType, filterValue: s.label });
          });
        }
        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/all_order_items?limit=${itens}&page=${page}&filters=${data}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateItemAdmin: async (
        itemId,
        status,
        dateProcessing,
        dateConciliation,
      ) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.order}/item/${itemId}`,
            {
              status,
              date_processing: dateProcessing,
              date_conciliation: dateConciliation,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
        // },
      },

      updateBatchItemAdmin: async (
        itens,
        status,
        dateProcessing,
        dateConciliation,
      ) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/items/batchupdate`,
            {
              status,
              date_processing: dateProcessing,
              date_conciliation: dateConciliation,
              itens,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
        // },
      },
      // http://localhost:3001/api/cnab?limit=10&page=1
      getCnabFiles: async (extra, itens, page, status, bank) => {
        const listFilters = [];
        if (status?.length > 0) {
          status.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'createdAt' ? s.date : encodeURIComponent(s.label),
            });
          });
        }
        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'createdAt'
                ? extra.date
                : encodeURIComponent(extra.label),
          });

        if (bank?.value !== '') {
          listFilters.push({
            filter: 'bankAccountId',
            filterValue: bank.value,
          });
        }

        // if (order)
        //   listFilters.push({ filter: 'orderNumber', filterValue: order });
        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axios.get(
            `${apiRoutes.cnab}?limit=${itens}&page=${page}&filters=${data}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAcessoFiles: async (extra, itens, page, status) => {
        const listFilters = [];
        if (status?.length > 0) {
          status.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'created_at'
                  ? s.date
                  : encodeURIComponent(s.label),
            });
          });
        }
        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'created_at'
                ? extra.date
                : encodeURIComponent(extra.label),
          });

        // if (order)
        //   listFilters.push({ filter: 'orderNumber', filterValue: order });
        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axios.get(
            `${apiRoutes.acesso}?limit=${itens}&page=${page}&filters=${data}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAcessoProcess: async (statusSelected, extra, itens, page, status) => {
        const listFilters = [];
        if (statusSelected.value !== '') {
          listFilters.push({
            filter: 'status',
            filterValue: statusSelected.value,
          });
        }
        if (status?.length > 0) {
          status.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'created_at'
                  ? s.date
                  : encodeURIComponent(s.label),
            });
          });
        }
        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'created_at'
                ? extra.date
                : encodeURIComponent(extra.label),
          });
        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axios.get(
            `${apiRoutes.acessoProcess}?limit=${itens}&page=${page}&filters=${data}&orderBy=DESC`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.commercialAgent = {
      getMetrics: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/${this.currentUser.id}/metrics`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getSummary: async (cancel, page, itens, date) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/0/summary?page=${page}&limit=${itens}&date=${date}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getSummaryYear: async (cancel, agentId, date) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/${agentId}/summary/year?date=${date}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getSummaryXls: async (cancel, date) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/0/summary/xls?date=${date}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getSummaryDetails: async (cancel, summaryId, page, itens) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/summary/${summaryId}/orders?page=${page}&limit=${itens}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      changeStatus: async (status, summaryIds, comments) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.commercialagent}/summary/status`,
            {
              summaryIds,
              status,
              comments,
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      changeStatusBySummary: async (status, summaryIds) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.commercialagent}/summary/all/status`,
            {
              summaryIds,
              status,
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getSummaryDetailsXls: async (cancel, summaryId) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/summary/${summaryId}/orders/xls`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getInvoices: async (cancel, page, itens) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/11/invoices?page=${page}&limit=${itens}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getContracts: async (
        productSelected,
        statusSelected,
        extra,
        itens,
        page,
        cancel,
        search,
      ) => {
        const listFilters = [];

        if (productSelected !== '')
          listFilters.push({
            filter: 'productType',
            filterValue: productSelected,
          });
        if (statusSelected !== '')
          listFilters.push({ filter: 'status', filterValue: statusSelected });
        if (extra)
          listFilters.push({ filter: extra.byType, filterValue: extra.label });
        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({ filter: s.byType, filterValue: s.label });
          });
        }

        const data = JSON.stringify(listFilters);
        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}/11/contracts?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      sendInvoice: async (
        summarysId,
        nfNumber,
        amount,
        generatedAt,
        commercialAgentId,
        doc,
      ) => {
        console.log(
          summarysId,
          nfNumber,
          amount,
          generatedAt,
          commercialAgentId,
          doc,
        );
        const form = new FormData();
        form.append('summarys_id', JSON.stringify(summarysId));
        form.append('nf_number', nfNumber);
        form.append('amount', amount);
        form.append('generated_at', generatedAt);
        form.append('commercial_agent_id', commercialAgentId);
        form.append('doc', doc);
        try {
          const response = await this.axios.post(
            `${apiRoutes.commercialagent}/sendnf`,
            form,
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      sendPayment: async summarysId => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.commercialagent}/pay`,
            { summary_id: summarysId, payment_date: new Date() },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.companyAgent = {
      create: async (
        companyName,
        cnpj,
        contactName,
        contactCpf,
        phone,
        mobile,
        email,
        postalCode,
        streetName,
        number,
        complement,
        district,
        city,
        state,
      ) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.commercialagent}/`,
            {
              company_name: companyName,
              cnpj,
              contact_name: contactName,
              contact_cpf: contactCpf,
              phone,
              mobile,
              email,
              postal_code: postalCode,
              street_name: streetName,
              number,
              complement,
              district,
              city,
              state,
              country: 'Brasil',
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      edit: async (
        id,
        companyName,
        cnpj,
        contactName,
        contactCpf,
        phone,
        mobile,
        email,
        postalCode,
        streetName,
        number,
        complement,
        district,
        city,
        state,
      ) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.commercialagent}/${id}`,
            {
              company_name: companyName,
              cnpj,
              contact_name: contactName,
              contact_cpf: contactCpf,
              phone,
              mobile,
              email,
              postal_code: postalCode,
              street_name: streetName,
              number,
              complement,
              district,
              city,
              state,
              country: 'Brasil',
            },
          );

          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAll: async (statusSelected, extra, itens, page, cancel, search) => {
        const listFilters = [];
        if (statusSelected !== '')
          listFilters.push({ filter: 'status', filterValue: statusSelected });
        if (extra)
          listFilters.push({ filter: extra.byType, filterValue: extra.label });
        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({ filter: s.byType, filterValue: s.label });
          });
        }

        const data = JSON.stringify(listFilters);

        try {
          const response = await this.axios.get(
            `${apiRoutes.commercialagent}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      linkUser: async (userId, companyId) => {
        console.log('linkUser', userId, companyId);
        try {
          const response = await this.axios.post(
            `${apiRoutes.commercialagent}/user`,
            {
              commercial_agent_id: companyId,
              user_id: userId,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      unbidUser: async (userId, companyId) => {
        console.log('unBind', userId, companyId);
        try {
          const response = await this.axios.delete(
            `${apiRoutes.commercialagent}/${companyId}/${userId}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      delete: async companyId => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.commercialagent}/${companyId}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.contract = {
      create: async (
        isClient,
        contacts,
        contractors,
        defaultDeliveryAddress,
        commercialAgents,
        isAdministrationRate,
        productType,
        isCentralizedDelivery,
        isActive,
        bankAccountId,
        serviceCodeId,
        campaign,
        groupName,
        annualEstimatedAward,
        currency,
        signatureDate,
        expirationDate,
        totalCommissionRate,
        readjustmentPeriod,
        salesChannel,
        isNormalCalculation,
        isTransferPayment,
        isInvoiceTopup,
        // Condições comerciais
        administrationRate,
        administrationFee,
        exchangeFee,
        applyAdministrationRate,
        // Obrigatórios se product_type = "card"
        issuer,
        brand,
        isChipCard,
        isInternationalCard,
        isDisposable,
        allowWithdraw,
        balanceTransferFee,
        markupRate,
        // Pagos pelo Cliente Element
        issueFee,
        minimumLoadAmount,
        belowMinimumLoadFee,
        emergencyLoadFee,
        specialHandlingFee,
        // Pagos pelo Premiado
        monthlyFee,
        unlockFee,
        reissueFee,
        chargebackFee,
        chargebackRate,
        atmWithdrawFee,
        bankWithdrawFee,
        // Obrigatórios se product_type = "transfer"
        isTed,
        transferFee,
        rejectedTransactionFee,
        // Obrigatórios se product_type = "check"
        checkFee,
        // Obrigatórios se product_type = "voucher"
        voucherFee,
        // Entrega
        deliveryAgent,
        deliveryService,
        comments,
        signeeName,
        signeeCpf,
        signeePost,
        area,
        commissionCalculation,
        // virtual
        virtualMonthlyFee,
        virtualUnlockFee,
        virtualReissueFee,
        virtualChargebackFee,
        virtualChargebackRate,
        virtualAtmWithdrawFee,
        virtualMarkupRate,
        virtualIrFee,
        virtualSpecialHandlingFee,
        virtualMinimumLoadAmount,
        virtualBelowMinimumLoadFee,
        virtualEmergencyLoadFee,
        virtualBalanceTransferFee,
        virtualTransferFee,
        virtualIssueFee,
        typeCard
      ) => {
        try {
          const response = await this.axios.post(`${apiRoutes.contract}`, {
            is_client: isClient,
            contract_users: contacts,
            contractors,
            default_delivery_address: defaultDeliveryAddress,
            commercial_agents: commercialAgents,
            is_administration_rate: isAdministrationRate,
            product_type: productType,
            is_centralized_delivery: isCentralizedDelivery,
            is_active: isActive,
            bank_account_id: bankAccountId,
            service_code_id: serviceCodeId,
            campaign,
            group_name: groupName,
            annual_estimated_award: annualEstimatedAward,
            currency,
            signature_date: signatureDate,
            expiration_date: expirationDate,
            total_commission_rate: totalCommissionRate,
            readjustment_period: readjustmentPeriod,
            sales_channel: salesChannel,
            is_normal_calculation: isNormalCalculation,
            is_transfer_payment: isTransferPayment,
            is_invoice_topup: isInvoiceTopup,
            // Condições comerciais
            administration_rate: administrationRate,
            administration_fee: administrationFee,
            exchange_fee: exchangeFee,
            apply_administration_rate: applyAdministrationRate,
            // Obrigatórios se product_type = "card"
            issuer,
            brand,
            is_chip_card: isChipCard,
            is_international_card: isInternationalCard,
            is_disposable: isDisposable,
            allow_withdraw: allowWithdraw,
            balance_transfer_fee: balanceTransferFee,
            markup_rate: markupRate,
            // Pagos pelo Cliente Element
            issue_fee: issueFee,
            minimum_load_amount: minimumLoadAmount,
            below_minimum_load_fee: belowMinimumLoadFee,
            
            emergency_load_fee: emergencyLoadFee,
            special_handling_fee: specialHandlingFee,
            // Pagos pelo Premiado
            
            virtual_issue_fee: virtualIssueFee,
            virtual_minimum_load_amount: virtualMinimumLoadAmount,
            virtual_below_minimum_load_fee: virtualBelowMinimumLoadFee,
            virtual_markup_rate: virtualMarkupRate,
            virtual_ir_fee: virtualIrFee,
            virtual_special_hande_fee:virtualSpecialHandlingFee,
            virtual_balance_transfer_fee: virtualBalanceTransferFee,
            virtual_monthly_fee: virtualMonthlyFee,
            virtual_unlock_fee: virtualUnlockFee,
            virtual_reissue_fee: virtualReissueFee,
            virtual_chargeback_fee: virtualChargebackFee,
            virtual_chargeback_rate: virtualChargebackRate,
            virtual_atm_withdraw_fee: virtualAtmWithdrawFee,
            virtual_emergency_load_fee: virtualEmergencyLoadFee,
            virtual_transfer_fee: virtualTransferFee,

            bank_withdraw_fee: bankWithdrawFee,

            monthly_fee: monthlyFee,
            unlock_fee: unlockFee,
            reissue_fee: reissueFee,
            chargeback_fee: chargebackFee,
            chargeback_rate: chargebackRate,
            atm_withdraw_fee: atmWithdrawFee,
            
            // Obrigatórios se product_type = "transfer"
            is_ted: isTed,
            
            rejected_transaction_fee: rejectedTransactionFee,
            // Obrigatórios se product_type = "check"
            check_fee: checkFee,
            // Obrigatórios se product_type = "voucher"
            voucher_fee: voucherFee,
            // Entrega
            delivery_agent: deliveryAgent,
            delivery_service: deliveryService,
            comments,
            signee_name: signeeName,
            signee_cpf: signeeCpf,
            signee_post: signeePost,
            area,
            commission_calculation: commissionCalculation,
            card_type: typeCard
          });
          // console.log(response);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      searchClients: async (contract, page, limit, typeClient, search) => {
        try {
          const data = JSON.stringify([search]);
          // &filters=[{"filter":"individual","filterValue":"Adriana"}]
          const response = await this.axios.get(
            `${apiRoutes.contract}/topup_template/${contract}/individuals?limit=${limit}&page=${page}&type=${typeClient}&filters=${data}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      linkAgent: async (contractId, agentId, agentPercentage) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.contract}/${contractId}/commercial_agents`,
            {
              commercial_agent_id: agentId,
              agent_percentage: agentPercentage,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      unbidAgent: async contractId => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.contract}/${contractId}/commercial_agents`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateUser: async (users, id) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.contract}/${id}/users`,
            {
              users,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateContract: async (
        operatorFees,
        companyOperatingFees,
        cardFees,
        campaign,
        productDetails,
        deliveryAddress,
        revenue,
        contractId,
        groupName,
        FourSign,
      ) => {
        const {
          monthlyFee,
          unlockFee,
          reissueFee,
          chargebackFee, // 'R$ 0,00',
          atmWithdrawFee,
          markupRate, // '0,00',
          p2pTransferFee, // 'R$ 0,00',
          transferFee, // 'R$ 0,00',
          rejectedTransactionFee, // 'R$ 0,00',
          checkFee, // 'R$ 0,00',
          voucherFee, // 'R$ 0,00',

          // virtual
          virtualMonthlyFee,
          virtualUnlockFee,
          virtualReissueFee,
          virtualChargebackFee,
          virtualAtmWithdrawFee,
          virtualMarkupRate,
          
        } = operatorFees;
        const { physicalCard } = cardFees;

        const {
          rechargeFrequency,
          annualEstimatedAward,
          totalCommissionRate,
          isAdministrationRate,
          administrationRate,
          administrationFee,
          issueFee,
        } = campaign;

        const {
          product,
          productBrand,
          salesChannel, // 'direct',
          isInternationalCard,
          isChipCard, // true,
          issuer, // 'acesso',
          isGiftCard,
          // isVirtualCard,
          typeCard, 
          // eslint-disable-next-line camelcase
          commission_calculation,
        } = productDetails;
        console.log(productDetails);
        const {
          // eslint-disable-next-line camelcase
          ir_fee,
          balanceTransferFee, // 'R$ 0,00',
          minimumLoadAmount, // 'R$ 0,00',
          belowMinimumLoadFee, // 'R$ 0,00',
          emergencyLoadFee, // 'R$ 0,00',
          specialHandlingFee, // 'R$ 0,00',
          chargebackRate, // '0,00',
          rejectedTransactionFee: rejectedTransactionFeeC, // 'R$ 0,00',
          virtualIrFee, 
          virtualBalanceTransferFee,
          virtualMinimumLoadAmount,
          virtualBelowMinimumLoadFee,
          virtualEmergencyLoadFee,
          virtualSpecialHandlingFee,
          virtualChargebackRate,
          virtualIssueFee,
        } = companyOperatingFees;

        const { deliveryService, deliveryAgent, isCentralizedDelivery } =
          deliveryAddress;

        const { calculation, paymentMethod, isInvoiceTopup, bankAccountId } =
          revenue;

        let rejected = 'R$ 0,00';

        if (rejectedTransactionFeeC === 'R$ 0,00') {
          rejected = rejectedTransactionFee;
        } else {
          rejected = rejectedTransactionFeeC;
        }
        try {

          // 
          const response = await this.axios.put(
            `${apiRoutes.contract}/${contractId}`,
            {
              monthly_fee: cleanValue(monthlyFee),
              unlock_fee: cleanValue(unlockFee),
              reissue_fee: cleanValue(reissueFee),
              chargeback_fee: cleanValue(chargebackFee), // 'R$ 0,00',
              atm_withdraw_fee: cleanValue(atmWithdrawFee),
              markup_rate: cleanValue(markupRate), // '0,00',
              p2p_transfer_fee: cleanValue(p2pTransferFee), // 'R$ 0,00',
              transfer_fee: cleanValue(transferFee), // 'R$ 0,00',
              rejected_transaction_fee: cleanValue(rejected), // 'R$ 0,00',
              check_fee: cleanValue(checkFee), // 'R$ 0,00',
              voucher_fee: cleanValue(voucherFee),
              virtual_monthly_fee: cleanValue(virtualMonthlyFee),
              virtual_unlock_fee: cleanValue(virtualUnlockFee),
              virtual_reissue_fee: cleanValue(virtualReissueFee),
              virtual_chargeback_fee: cleanValue(virtualChargebackFee),
              virtual_atm_withdraw_fee: cleanValue(virtualAtmWithdrawFee),
              virtual_markup_rate: cleanValue(virtualMarkupRate),
              // campaing
              readjustment_period: rechargeFrequency,
              annual_estimated_award: cleanValue(annualEstimatedAward),
              total_commission_rate: cleanValue(totalCommissionRate),
              is_administration_rate: isAdministrationRate,
              administration_rate: administrationRate.replace(',', '.'),
              administration_fee: cleanValue(administrationFee),
              issue_fee: cleanValue(issueFee),
              // product
              product_type: product === 'prepaid_card' ? 'Card' : 'Transfer',
              brand: productBrand,
              sales_channel: salesChannel, // 'direct',
              is_international_card: isInternationalCard,
              is_chip_card: isChipCard, // true,
              issuer,
              allow_withdraw: !isGiftCard,
              // companyOpe
              ir_fee: cleanValue(ir_fee),
              card_type: Number(typeCard),
              // is_virtual_card: isVirtualCard,
              balance_transfer_fee: cleanValue(balanceTransferFee), // 'R$ 0,00',
              minimum_load_amount: cleanValue(minimumLoadAmount), // 'R$ 0,00',
              below_minimum_load_fee: cleanValue(belowMinimumLoadFee), // 'R$ 0,00',
              emergency_load_fee: cleanValue(emergencyLoadFee), // 'R$ 0,00',
              special_handling_fee: cleanValue(specialHandlingFee), // 'R$ 0,00',
              chargeback_rate: chargebackRate.replace(',', '.'), // '0,00',
              virtual_ir_fee: cleanValue(virtualIrFee),  
              virtual_balance_transfer_fee: cleanValue(virtualBalanceTransferFee),
              virtual_minimum_load_amount: cleanValue(virtualMinimumLoadAmount),
              virtual_below_minimum_load_fee: cleanValue(virtualBelowMinimumLoadFee),
              virtual_emergency_load_fee: cleanValue(virtualEmergencyLoadFee),
              virtual_special_handling_fee: cleanValue(virtualSpecialHandlingFee),
              virtual_chargeback_rate: cleanValue(virtualChargebackRate),
              virtual_issue_fee: cleanValue(virtualIssueFee),
              
              // rejected_transaction_fee: cleanValue(rejectedTransactionFee),
              // card
              physical_card: cleanValue(physicalCard), //
              // revenue
              is_normal_calculation: calculation === 'normal',
              is_transfer_payment: paymentMethod === 'transfer',
              is_invoice_topup: isInvoiceTopup,

              // deliveryService, deliveryAgent
              delivery_agent: deliveryAgent,
              delivery_service: deliveryService,
              is_centralized_delivery: isCentralizedDelivery,
              // eslint-disable-next-line camelcase
              commission_calculation,
              group_name: groupName,
              doc4sign_file: FourSign,
              bank_account_id: bankAccountId,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateDeliveryAddress: async (data, contractId) => {
        const {
          postalCode,
          street,
          number,
          complement,
          district,
          city,
          state,
          receiver,
          deliveryAgent,
          isCentralizedDelivery,
          deliveryService,
          id,
          otherAddress,
        } = data;
        try {
          const response = await this.axios.put(
            `${apiRoutes.contract}/${contractId}/delivery_address`,
            {
              id,
              postal_code: cleanCEP(
                isCentralizedDelivery ? postalCode : otherAddress.postalCode,
              ),
              street_name: isCentralizedDelivery ? street : otherAddress.street,
              number: isCentralizedDelivery ? number : otherAddress.number,
              complement: isCentralizedDelivery
                ? complement
                : otherAddress.complement,
              district: isCentralizedDelivery
                ? district
                : otherAddress.district,
              city: isCentralizedDelivery ? city : otherAddress.city,
              state: isCentralizedDelivery ? state : otherAddress.state,
              recipient: receiver,
              delivery_agent: deliveryAgent,
              is_centralized_delivery: isCentralizedDelivery,
              delivery_service: deliveryService,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateCompany: async (data, address, id, cnpj) => {
        const {
          companyName,
          tradingName,
          email,
          phone,
          mobile,
          website,
          comments,
          contacts,
        } = data;
        const {
          postalCode,
          street,
          number,
          complement,
          district,
          city,
          state,
        } = address;
        try {
          const response = await this.axios.put(
            `${apiRoutes.contract}/${id}/legal_entity/${cnpj}`,
            {
              company_name: companyName,
              trading_name: tradingName,
              email,
              phone,
              mobile,
              website,
              comments,
              contract_users: contacts,
              postal_code: postalCode,
              street_name: street,
              number,
              complement,
              district,
              city,
              state,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      get: async (
        productSelected,
        statusSelected,
        extra,
        itens,
        page,
        cancel,
        search,
      ) => {
        const listFilters = [];

        if (productSelected !== '')
          listFilters.push({
            filter: 'productType',
            filterValue: productSelected,
          });
        if (statusSelected !== '')
          listFilters.push({ filter: 'status', filterValue: statusSelected });
        if (extra)
          listFilters.push({ filter: extra.byType, filterValue: extra.label });
        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({ filter: s.byType, filterValue: s.label });
          });
        }

        const data = JSON.stringify(listFilters);
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getContractType: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/contractsType`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getByProduct: async (cancel, product, mustDefault) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/type/${product}?mustDefault=${mustDefault}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getSubCompanies: async (cancel, contractId) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/legalentity/${contractId}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getListSubCompanies: async (cancel, contractId) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/subcompanies/${contractId}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      associateContracts: async (userId, listContracts) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.contract}/individual/connect/contracts`,
            {
              userId,
              listContracts,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      unbindContract: async id => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.contract}/unbindContract/${id}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAll: async (cancel, mustDefault) => {
        try {
          const card = this.axios.get(
            `${apiRoutes.contract}/type/Card?mustDefault=${mustDefault}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          const transfer = this.axios.get(
            `${apiRoutes.contract}/type/Transfer?mustDefault=${mustDefault}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          const response = await Promise.all([card, transfer]);
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getPlanModel: async (cancel, contractid) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/topup_template/${contractid}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      changeStatus: async (contractid, status) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.contract}/${contractid}`,
            {
              is_active: status,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      cancelAllCards: async contractid => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.card}/cancel/contract/${contractid}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getLegalEntity: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/legalentity/${id}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAwardLegalEntities: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/${id}/award_legal_entities`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      uploadContract: async (contract, id) => {
        try {
          const formData = new FormData();
          formData.append('doc', contract);

          const response = await this.axios.post(
            `${apiRoutes.contract}/${id}/upload`,
            formData,
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getAwardIndividuals: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/${id}/award_individuals`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getComercialAgents: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/${id}/commercial_agents`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getUsers: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/${id}/users`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      addCnpjs: async (legalEntities, id) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.contract}/${id}/legal_entity`,
            legalEntities,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      removeCnpjs: async (idContract, cnpj) => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.contract}/${idContract}/legal_entity/${cnpj}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getContract: async (cancel, id) => {
        try {
          const response = await this.axios.get(`${apiRoutes.contract}/${id}`, {
            cancelToken: cancelToken(cancel),
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getContract4Sign: async (cancel, uid) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/docs/d4sign/${uid}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getContract4SignAll: async (cancel, status) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.contract}/docs/d4sign/status/${status}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.invoice = {
      viewReport: async (extra, chipData, cancel, itens, page) => {
        const list = [];

        if (extra) {
          if (extra.byType === 'createdAt' || extra.byType === 'paymentDate') {
            list.push({
              filter: extra.byType,
              month: moment(extra.date).format('MM'),
              year: moment(extra.date).format('YYYY'),
            });
          } else {
            list.push({ filter: extra.byType, filterValue: extra.label });
          }
        }

        if (chipData.length > 0) {
          chipData.forEach(c => {
            if (c.byType === 'createdAt' || c.byType === 'paymentDate') {
              list.push({
                filter: c.byType,
                month: moment(c.date).format('MM'),
                year: moment(c.date).format('YYYY'),
              });
            } else {
              list.push({ filter: c.byType, filterValue: c.label });
            }
          });
        }
        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.invoice}/viewreport?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      xmlReport: async fileName => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.invoice}/xmlreport?fileName=${fileName}`,
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      xlsReport: async (chipData, cancel) => {
        const list = [];
        if (chipData.length > 0) {
          chipData.forEach(c => {
            if (c.byType === 'createdAt' || c.byType === 'paymentDate') {
              list.push({
                filter: c.byType,
                month: moment(c.date).format('MM'),
                year: moment(c.date).format('YYYY'),
              });
            } else {
              list.push({ filter: c.byType, filterValue: c.label });
            }
          });
        }

        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.invoice}/xlsreport?filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      viewFatReport: async (extra, chipData, bank, cancel, itens, page) => {
        const list = [];

        if (extra) {
          if (extra.byType === 'dateIssue' || extra.byType === 'paymentDate') {
            list.push({
              filter: extra.byType,
              month: moment(extra.date).format('MM'),
              year: moment(extra.date).format('YYYY'),
            });
          } else {
            list.push({ filter: extra.byType, filterValue: extra.label });
          }
        }

        if (chipData.length > 0) {
          chipData.forEach(c => {
            if (c.byType === 'dateIssue' || c.byType === 'paymentDate') {
              list.push({
                filter: c.byType,
                month: moment(c.date).format('MM'),
                year: moment(c.date).format('YYYY'),
              });
            } else {
              list.push({ filter: c.byType, filterValue: c.label });
            }
          });
        }
        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.invoice}/viewfatreport?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      xlsFatReport: async (chipData, cancel) => {
        const list = [];
        if (chipData.length > 0) {
          chipData.forEach(c => {
            if (c.byType === 'createdAt' || c.byType === 'paymentDate') {
              list.push({
                filter: c.byType,
                month: moment(c.date).format('MM'),
                year: moment(c.date).format('YYYY'),
              });
            } else {
              list.push({ filter: c.byType, filterValue: c.label });
            }
          });
        }
        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.invoice}/xlsfatreport?filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.individual = {
      update: async (userId, name, cpf) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.individual}/${userId}`,
            {
              name,
              cpf,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAll: async () => {
        try {
          const response = await this.axios.get(`${apiRoutes.individual}`);
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.rps = {
      getAll: async (itens, page, dataIni, dataFim, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.rps}?dateInit=${dataIni}&dateEnd=${dataFim}&limit=${itens}&page=${page}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      sendFile: async (file, id) => {
        const formData = new FormData();
        formData.append('doc', file);
        formData.append('id', id);
        try {
          const response = await this.axios.post(
            `${apiRoutes.rps}/updaterps`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.bankly = {
      getAll: async (itens, page, dataIni, dataFim, status, cancel) => {
        const list = [];
        if (status !== '') {
          list.push({ filter: 'status', filterValue: status });
        }
        list.push({
          filter: 'dateCreated',
          dataIni: moment(dataIni)?.toISOString(),
          dataFim: moment(dataFim)?.toISOString(),
        });
        //  `${apiRoutes.bankly}?dateInit=${dataIni}&dateEnd=${dataFim}&limit=${itens}&page=${page}`,
        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.bankly}?filters=${data}&limit=${itens}&page=${page}&orderBy=DESC`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.moviment = {
      get: async (extra, filter, status, searchType, itens, page, cancel) => {
        const list = [];
        if (status !== '') list.push({ filter: 'status', filterValue: status });
        // if (search !== '')
        //   list.push({ filter: searchType, filterValue: search });
        // if (searchType.length > 0) {
        //   searchType.forEach(search => {
        //     list.push({ filter: search.byType, filterValue: search.label });
        //   });
        // }
        if (searchType.length > 0) {
          searchType.forEach(s => {
            list.push({
              filter: s.byType,
              filterValue:
                s.byType === 'dateProcessing'
                  ? s.date
                  : encodeURIComponent(s.label),
              filterValueEnd:
                s.byType === 'dateProcessing'
                  ? s.dateFim
                  : encodeURIComponent(s.label),
            });
          });
        }
        // if (extra)
        //   list.push({ filter: extra.byType, filterValue: extra.label });
        if (extra)
          list.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'dateProcessing'
                ? extra.date
                : encodeURIComponent(extra.label),
            filterValueEnd:
              extra.byType === 'dateProcessing'
                ? extra.dateFim
                : encodeURIComponent(extra.label),
          });

        list.push({ filter: 'type', filterValue: filter });

        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/paymentReleases?limit=${itens}&page=${
              page + 1
            }&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getPlan: async (filter, status, searchType, cancel) => {
        const list = [];
        if (status !== '') list.push({ filter: 'status', filterValue: status });

        // if (searchType.length > 0) {
        //   searchType.forEach(search => {
        //     list.push({ filter: search.byType, filterValue: search.label });
        //   });
        // }
        if (searchType.length > 0) {
          searchType.forEach(s => {
            list.push({
              filter: s.byType,
              filterValue:
                s.byType === 'dateProcessing'
                  ? s.date
                  : encodeURIComponent(s.label),
              filterValueEnd:
                s.byType === 'dateProcessing'
                  ? s.dateFim
                  : encodeURIComponent(s.label),
            });
          });
        }

        list.push({ filter: 'type', filterValue: filter });

        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/paymentReleasesXlsReport?filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getDetails: async (id, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.order}/logs/${id}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.paymentReleases = {
      get: async (
        extra,
        bank,
        filter,
        status,
        searchType,
        // search,
        itens,
        page,
        cancel,
      ) => {
        const list = [];

        if (status !== '') list.push({ filter: 'status', filterValue: status });

        if (extra) {
          list.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'datePayment'
                ? extra.date
                : encodeURIComponent(extra.label),
          });
        }

        if (filter !== '') list.push({ filter: 'type', filterValue: filter });

        if (bank !== '') list.push({ filter: 'bank', filterValue: bank });

        searchType.forEach(s => {
          list.push({
            filter: s.byType,
            filterValue: encodeURIComponent(s.label),
          });
        });
        const data = JSON.stringify(list);
        try {
          // /api/order/invoices?limit=1&page=1
          // /allPaymentReleases?limit=${itens}&page=${
          //   page + 1
          // }
          const response = await this.axios.get(
            `${apiRoutes.order}/allPaymentReleases?limit=${itens}&page=${
              page + 1
            }&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      getFile: async (orderItems, bankNumber) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/paymentReleaseFile`,
            {
              bankNumber,
              orderItems,
            },
            { timeout: 2000 * 60 },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      reconcileVerify: async file => {
        const formData = new FormData();
        formData.append('doc', file);
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/reconcileVerify`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      reconcilie: async (file, bankNumber, cnabId, cnabType) => {
        const formData = new FormData();
        formData.append('doc', file);
        formData.append('bank_number', bankNumber);
        formData.append('cnab_id', cnabId);
        formData.append('cnab_type', cnabType);
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/reconcilePaymentReleaseFile`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      cancel: async ids => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.order}/cancel/paymentReleases`,
            {
              payment_releases: ids,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.product = {
      get: async (status, search, itens, page, cancel) => {
        // let query = '';
        // if (status !== '') query = `filter=isActive&filterValue=${status}&`;
        // if (search) query = `filter=description&filterValue=${search}&`;
        const list = [];
        if (status !== '')
          list.push({ filter: 'isActive', filterValue: status });
        if (search !== '')
          list.push({ filter: 'invoiceString', filterValue: search });

        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.product}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      newCard: async (description, productType) => {
        try {
          const response = await this.axios.post(`${apiRoutes.product}/`, {
            can_client_request: 'true',
            is_active: 'true',
            is_percentage: 'false',
            is_debit_noteable: 'false',
            is_virtual_voucher: 'false',
            voucher_min_amount: '0.0',
            voucher_max_amount: '0.0',
            voucher_min_order: '0.0',
            voucher_lot: '0',
            voucher_admin_rate: '0',
            comments: ' ',
            invoice_string: description,
            description,
            product_type: productType,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      alterStatus: async productId => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.product}/active/${productId}`,
            {
              isActive: 'false',
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      // }

      // getCard: async (itens, page) => {
      //   try {
      //     const response = await this.axios.get
      // card(`${apiRoutes.product}/type/Card?limit=${itens}&page=${page}`);
      //     return response.data;
      //   } catch (e) {
      //     return throwFormattedError(e);
      //   }
      // },

      // getCard: async (status, search, itens, page, cancel) => {
      getCard: async (status, search, itens, page, cancel) => {
        const list = [];
        if (status !== '') list.push({ filter: 'status', filterValue: status });
        if (search !== '') list.push({ filter: 'proxy', filterValue: search });

        const data = JSON.stringify(list);

        try {
          const response = await this.axios.get(
            `${apiRoutes.card}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getCardAdmin: async (
        extra,
        itens,
        page,
        status,
        statusBankly,
        flag,
        search,
        cancel,
      ) => {
        const listFilters = [];
        if (status !== '')
          listFilters.push({ filter: 'status', filterValue: status });

        if (statusBankly !== '')
          listFilters.push({
            filter: 'bank_status',
            filterValue: statusBankly,
          });

        if (flag !== '')
          listFilters.push({ filter: 'brand', filterValue: flag });

        if (extra)
          listFilters.push({ filter: extra.byType, filterValue: extra.label });
        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({ filter: s.byType, filterValue: s.label });
          });
        }

        const data = JSON.stringify(listFilters);
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAllCardAdmin: async (
        extra,
        itens,
        page,
        status,
        flag,
        search,
        cancel,
        value,
      ) => {
        console.log(value);
        const listFilters = [];
        // const listFilters = [{ filter: 'validity', filterValue: value }];
        if (status !== '')
          listFilters.push({ filter: 'status', filterValue: status });

        if (flag !== '')
          listFilters.push({ filter: 'brand', filterValue: flag });

        // if (extra)
        //   listFilters.push({ filter: extra.byType, filterValue: extra.label });
        if (extra)
          listFilters.push({
            filter: extra.byType,
            filterValue:
              extra.byType === 'validity'
                ? extra.date
                : encodeURIComponent(extra.label),
          });
        // if (search.length > 0) {
        //   search.forEach(s => {
        //     listFilters.push({ filter: s.byType, filterValue: s.label });
        //   });
        // }
        if (search.length > 0) {
          search.forEach(s => {
            listFilters.push({
              filter: s.byType,
              filterValue:
                s.byType === 'validity' ? s.date : encodeURIComponent(s.label),
            });
          });
        }

        console.log(listFilters);
        const data = JSON.stringify(listFilters);
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/base/all?limit=${itens}&page=${page}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateCardAdmin: async (
        cardId,
        status,
        cardNumber,
        proxy,
        brand,
        allowWithdraw,
        cardType,
        // isVirtualCard,
        issuer,
        goodThrough,
      ) => {
        try {
          const response = await this.axios.put(`${apiRoutes.card}/${cardId}`, {
            status,
            proxy,
            card_number: cardNumber,
            brand,
            allow_withdraw: allowWithdraw,
            // is_virtual_card: isVirtualCard,
            card_type: cardType,
            issuer,
            good_through: goodThrough,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      updateCardAdminBatch: async (itens, status) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/batchupdate`,
            {
              itens,
              status,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },

      updateCardAdminTotal: async (
        cardId,
        status,
        cardNumber,
        contractId,
        individualId,
      ) => {
        try {
          const response = await this.axios.put(`${apiRoutes.card}/${cardId}`, {
            status,
            card_number: cardNumber,
            contract_id: contractId,
            individual_id: individualId,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
    this.cards = {
      newPersonalizedCard: async (name, fileFull, fileBack, fileFront) => {
        const formData = new FormData();
        formData.append('full', fileFull);
        formData.append('front', fileFront);
        formData.append('back', fileBack);
        formData.append('name', name);

        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/customized`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      associateContracts: async (cardId, contracts) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/customized/${cardId}/contracts`,
            { contracts },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      unbindContract: async (cardId, contract) => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.card}/customized/${cardId}/contracts/${contract}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      exportXLS: async (cardId, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/orders/${cardId}/exportXLS`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      baseAll: async cancel => {
        // http://192.168.15.10:3000/api/card/base/all/file/
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/base/all/file`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      summary: async (month, year, cancel) => {
        let query = '';
        if (year) {
          query += `?year=${year.value}`;
        }
        if (month) {
          query += `&month=${month.value}`;
        }
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/summary/count${query}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      expirationSummary: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/base/goodThrough`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      exportPDF: async (cardId, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/orders/${cardId}/exportPDF`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getTypeCards: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/customized`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getContracts: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/customized/${id}/contracts`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      get: async (
        extra,
        status,
        searchType,
        productSelected,
        itens,
        page,
        cancel,
      ) => {
        const list = [];
        if (extra)
          list.push({ filter: extra.byType, filterValue: extra.label });
        if (status !== '') list.push({ filter: 'status', filterValue: status });
        if (searchType.length > 0) {
          searchType.forEach(search => {
            list.push({
              filter: search.byType,
              filterValue: encodeURIComponent(search.label),
            });
          });
        }
        if (productSelected)
          list.push({ filter: 'product', filterValue: productSelected });

        const data = JSON.stringify(list);

        try {
          const response = await this.axios.get(
            `${apiRoutes.card}?limit=${itens}&page=${page + 1}&filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getBase: async (status, searchType, productSelected, cancel) => {
        const list = [];
        if (status !== '') list.push({ filter: 'status', filterValue: status });
        if (searchType.length > 0) {
          searchType.forEach(search => {
            list.push({ filter: search.byType, filterValue: search.label });
          });
        }
        if (productSelected)
          list.push({ filter: 'product', filterValue: productSelected });
        // if (search !== '')
        //   list.push({ filter: searchType, filterValue: search });

        // list.push({ filter: 'type', filterValue: filter });
        const data = JSON.stringify(list);
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/list?filters=${data}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getSample: async cancel => {
        // api/card/model/linkToIndividual
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/model/linkToIndividual`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getMoviment: async (cardId, itens, page, cancel) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.card}/orders/${cardId}?limit=${itens}&page=${
              page + 1
            }`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      changeStatus: async (cardId, data) => {
        // patch ->/api/card/:cardId -> atualização passando o status
        // Blocked, Active, Canceled
        // const status = JSON.stringify(data);
        try {
          const response = await this.axios.patch(
            `${apiRoutes.card}/[${cardId}]`,
            {
              status: data,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      vincCard: async (proxy, name, cpf) => {
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/linkToIndividual`,
            {
              proxy,
              name,
              cpf,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      vincBookCard: async doc => {
        // api/card/batch/linkToIndividual
        const formData = new FormData();
        formData.append('doc', doc);
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/batch/linkToIndividual`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      replaceCard: async (
        proxy,
        cpf,
        cobrarEmpresa,
        notMoney,
        endereco,
        numero,
        estado,
        complemento,
        bairro,
        cidade,
        cep,
        aosCuidados,
      ) => {
        // proxy, cpf, cobrarEmpresa, endereco, numero, estado, complemento, bairro, cidade, cep, aosCuidados
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/replaceCard`,
            {
              proxy,
              cpf,
              cobrarEmpresa,
              notMoney,
              endereco,
              numero,
              estado,
              complemento,
              bairro,
              cidade,
              cep,
              aosCuidados,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      uploadBaseCards: async (
        doc,
        brand,
        issuer,
        allowWithraw,
        goodThrough,
        flashContract,
      ) => {
        const formData = new FormData();
        formData.append('doc', doc);
        formData.append('brand', brand);
        formData.append('issuer', issuer);
        formData.append('allow_withraw', allowWithraw);
        formData.append('good_through', goodThrough);
        formData.append('flash_contract', flashContract);
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/uploadStockCardBase`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }

        //         rota: http://192.168.0.102:3000/api/card/uploadStockCardBase
        // parametros:
        // doc: arquivo que sera enviado
        // brand: bandeira, tipo string, opcional
        // issuer: emissor, tipo string, opcional
        // allow_withdraw: saqueCompra, tipo boolean, se true é saque/compra, opcional
        // card_name: nome do cartão, tipo string, opcional
      },

      updatebankstatus: async doc => {
        const formData = new FormData();
        formData.append('doc', doc);
        try {
          const response = await this.axios.post(
            `${apiRoutes.card}/updatebankstatus`,
            formData,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }

        //         rota: http://192.168.0.102:3000/api/card/uploadStockCardBase
        // parametros:
        // doc: arquivo que sera enviado
        // brand: bandeira, tipo string, opcional
        // issuer: emissor, tipo string, opcional
        // allow_withdraw: saqueCompra, tipo boolean, se true é saque/compra, opcional
        // card_name: nome do cartão, tipo string, opcional
      },
      process: async (status, items) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.acessoProcess}/status`,
            {
              status,
              items,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.oportunity = {
      create: async data => {
        const {
          campaign,
          cnpj,
          companyName,
          tradingName,
          website,
          email,
          phone,
          mobile,
          comments,
          contacts,
        } = data;
        try {
          const response = await this.axios.post(
            `${apiRoutes.campaigns}/${campaign.value}/lead`,
            {
              cnpj,
              company_name: companyName,
              trading_name: tradingName,
              website,
              email,
              phone,
              mobile,
              comments,
              orig: 'system',
              status: 'Created',
              personalData: contacts,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      createPublic: async data => {
        const {
          campaign,
          cnpj,
          companyName,
          tradingName,
          website,
          email,
          phone,
          mobile,
          comments,
          contacts,
        } = data;
        try {
          const response = await this.axios.post(
            `${apiRoutes.campaigns}/${campaign.value}/lead`,
            {
              cnpj,
              company_name: companyName,
              trading_name: tradingName,
              website,
              email,
              phone,
              mobile,
              comments,
              orig: 'web',
              status: 'Pending',
              personalData: contacts,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      update: async (id, data) => {
        const {
          // campaign,
          cnpj,
          companyName,
          tradingName,
          website,
          email,
          phone,
          mobile,
          comments,
          contacts,
        } = data;
        try {
          const response = await this.axios.put(
            `${apiRoutes.campaigns}/lead/${id}`,
            {
              cnpj,
              company_name: companyName,
              trading_name: tradingName,
              website,
              email,
              phone,
              mobile,
              comments,
              status: 'Created',
              personalData: contacts,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.campaign = {
      get: async (cancel, page, size) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.campaigns}?limit=${size}&page=${page}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      isValid: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.campaigns}/${id}/status`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getLead: async (cancel, page, size) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.campaigns}/leads?limit=${size}&page=${page}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getLeadById: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.campaigns}/lead/${id}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getById: async (cancel, id) => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.campaigns}/${id}`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      new: async (name, expiresIn, comments) => {
        try {
          const response = await this.axios.post(`${apiRoutes.campaigns}`, {
            name,
            expiresIn,
            comments,
          });
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      edit: async (id, data) => {
        const { name, expiresIn, comments } = data;
        try {
          const response = await this.axios.put(
            `${apiRoutes.campaigns}/${id}`,
            {
              name,
              expiresIn,
              comments,
            },
          );
          return response;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.permissions = {
      create: async (name, description, permissions) => {
        try {
          const response = await this.axios.post(`${apiRoutes.permissions}`, {
            name,
            description,
            permissions,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      update: async (name, description, permissions, id) => {
        try {
          const response = await this.axios.put(
            `${apiRoutes.permissions}/${id}`,
            {
              name,
              description,
              permissions,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      delete: async id => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.permissions}/${id}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAll: async cancel => {
        try {
          const response = await this.axios.get(`${apiRoutes.permissions}`, {
            cancelToken: cancelToken(cancel),
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };

    this.tutorials = {
      create: async data => {
        const { title, subject: description, media: videoUrl } = data;
        try {
          const response = await this.axios.post(`${apiRoutes.tutorials}`, {
            title,
            description,
            videoUrl,
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      update: async (data, id) => {
        const { title, subject: description, media: videoUrl } = data;
        try {
          const response = await this.axios.put(
            `${apiRoutes.tutorials}/set/all/${id}`,
            {
              title,
              description,
              videoUrl,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      updateStatus: async (status, id) => {
        const ids = JSON.stringify(id);
        try {
          const response = await this.axios.put(
            `${apiRoutes.tutorials}/set/${ids}`,
            {
              status: status === 'Publicado' ? 1 : 0,
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getAll: async cancel => {
        try {
          const response = await this.axios.get(`${apiRoutes.tutorials}`, {
            cancelToken: cancelToken(cancel),
          });
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      getActives: async cancel => {
        try {
          const response = await this.axios.get(
            `${apiRoutes.tutorials}/active`,
            {
              cancelToken: cancelToken(cancel),
            },
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
      delete: async id => {
        try {
          const response = await this.axios.delete(
            `${apiRoutes.tutorials}/${id}`,
          );
          return response.data;
        } catch (e) {
          return throwFormattedError(e);
        }
      },
    };
  }
}

const api = new Api();
export default api;
