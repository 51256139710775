/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  // FormGroup,
  // FormControlLabel,
  // // Checkbox,
  // RadioGroup,
  // Radio,
  // FormControl,
  TextField,
  Switch,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
// import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// import locale from 'date-fns/locale/pt-BR';
// import DateFnsUtils from '@date-io/date-fns';
import readXlsxFile from 'read-excel-file';
// import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';
import TablePremio from '../../../components/tables/premiados/TablePremio';
// eslint-disable-next-line no-unused-vars
import api from '../../../service/axios/api';
import MyButton from '../../../components/button/MyButton';

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));

export default function UploadPlan({
  myFile,
  setMyfile,
  orders,
  setOrders,
  fileXls,
  contract,
  setLoad,
  setMsg,
  orderName,
  setOrderName,
  nfComments,
  setNfComments,
  isNew,
  paymentDate,
  setPaymentDate,
  nf,
  setNf,
  boleto,
  setBoleto,
}) {
  // const fileXls = useRef(null);
  // const [myFile, setMyfile] = useState();
  // const [orders, setOrders] = useState([]);
  // const [canNf, setCanNf] = useState(true);
  const { t } = useTranslation();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false)

  // const [boleto, setBoleto] = useState(false);
  // const [nf, setNf] = useState(false);

  const templateDownload = () => {
    setMsg(t('newOrder.uploadPlan.searchMsg'));
    setLoad(true);
    const cancel = new AbortController();
    if (contract) {
      api.contract
        .getPlanModel(cancel, contract.value)
        .then(res => {
          // const url = window.URL.createObjectURL(new Blob([res]));
          const url = res.retorno.link;
          const link = document.createElement('a');
          link.href = url;
          // link.setAttribute('download', 'carga_template.xlsx'); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => console.log(err))
        .finally(() => setLoad(false));
    }
  };

  useEffect(() => {
    if (myFile) {
      const dataTable = [];
      readXlsxFile(myFile).then(r => {
        const keys = r[0];
        r.forEach((data, i) => {
          if (i > 0) {
            if (
              data[5] ||
              data[1] === 'ReplacementForFraud' ||
              data[1] === 'CardIssueFee' ||
              data[1] === 'DCP'
            ) {
              dataTable.push({
                [keys[0]]: data[0],
                [keys[1]]: data[1],
                [keys[2]]: data[2],
                [keys[3]]: data[3],
                [keys[4]]: data[4],
                [keys[5]]: data[5] ? data[5] : 0.0,
                [keys[6]]: data[6],
                [keys[7]]: data[7],
                [keys[8]]: data[8],
                [keys[9]]: data[9],
                [keys[10]]: data[10],
                [keys[11]]: data[11],
                [keys[12]]: data[12],
                [keys[13]]: data[13],
                [keys[14]]: data[14],
                [keys[15]]: data[15],
                [keys[16]]: data[16],
                [keys[17]]: data[17],
                [keys[18]]: data[18],
                [keys[19]]: data[19],
                [keys[20]]: data[20],
              });
            }
          }
        });
        setOrders(dataTable);
      });
    }
  }, [myFile]);

  // const handleChangeBoleto = e => {
  //   if (e) {
  //     setBoleto(e);
  //     setNf(e);
  //   } else {
  //     setBoleto(e);
  //   }
  // };

  const handleChangeNf = e => {
    setNf(e.target.value)
    if (e.target.value === 'NFND') {
      setOpenModal(true)
    }
  };

  const cleanPlan = () => {
    setMyfile(null);
    setOrders([]);
  };

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          {t('newOrder.uploadPlan.label')}
        </h6>
        {/* <h6 className={styles.textBoxInform}>Pedido P000000</h6> */}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item>
            <p
              className="label-planilhas"
              styles={{ cursor: 'pointer' }}
              onClick={templateDownload}
            >
              <img
                id="planilha"
                src="../img/download.svg"
                alt="logo"
                style={{ marginRight: '0px' }}
                className="img-planilhas"
              />
            </p>
          </Grid>
          <Grid item>
            <p className="label-planilhas" styles={{ cursor: 'pointer' }}>
              <img
                id="planilha"
                src="../img/upload.svg"
                alt="logo"
                className="img-planilhas"
                onClick={() => fileXls.current.click()}
              />
              {t('newOrder.uploadPlan.planAwarded')}
              {myFile && (
                <Button
                  variant="outlined"
                  style={{
                    height: 20,
                    color: 'red',
                    marginLeft: 15,
                    borderColor: 'red',
                  }}
                  onClick={cleanPlan}
                >
                  LIMPAR
                </Button>
              )}
            </p>
            <input
              ref={fileXls}
              type="file"
              style={{
                opacity: 0,
              }}
              // accept="media_type"
              onChange={() => setMyfile(fileXls.current.files[0])}
              className="form-control"
              id="inputNumSerie"
            // placeholder="Arquivo DWG do projeto"
            />
          </Grid>
        </div>
        {isNew && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Tipo de documento</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={handleChangeNf}
                value={nf}
              >
                <FormControlLabel value="NF" control={<Radio />} label="NF" />
                <FormControlLabel value="NFND" control={<Radio />} label="NF+ND" />
                {!api.currentUser.user.role.is_client && <FormControlLabel value="ND" control={<Radio />} label="ND" />}
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </div>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <Grid item xs={11}>
          <TablePremio
            orders={orders}
            totalPages={1}
            page={1}
            // setPage={setPage}
            itens={10}
          // setItens={setItens}
          />
        </Grid>
        {isNew && (
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={5}
              style={{ marginTop: '15px', marginRight: '10px' }}
            >
              <TextField
                className={classes.text}
                variant="outlined"
                size="small"
                fullWidth
                placeholder={t('newOrder.uploadPlan.orderName')}
                value={orderName}
                onChange={e => setOrderName(e.target.value)}
                // inputRef={refQtdCards}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={5} style={{ marginTop: '15px', marginLeft: '10px' }}>
              <TextField
                className={classes.text}
                variant="outlined"
                size="small"
                fullWidth
                placeholder={t('newOrder.uploadPlan.comments')}
                value={nfComments}
                onChange={e => setNfComments(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog open={openModal}>
        <DialogTitle>Importante</DialogTitle>
        <DialogContent>
          <DialogContentText>Você selecionou a opção de emissão de NF + ND, com isso receberá uma Nota Fiscal com os valores de Serviços e uma Nota de Débito com os valores de repasse. Confirma?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
              backgroundColor: '#657588',
            }}
            onClick={() => {
              setNf('NF')
              setOpenModal(false)
            }}
            label="NÃO"
          />
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
            }}
            onClick={() => setOpenModal(false)}
            label="SIM"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
